// ProductDetails.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import "./styles.css"
import "./EditProduct/editInvent.css"

const ProductDetails =  () => {
  const [itemData, setItemData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const { itemId } = useParams();
  var {store_id}=useState(null);
  const fetchItemDetails = async () => {
    
    store_id=localStorage.getItem('store_id');
    try {
      const response = await fetch(`/api/v1/productInventoryItems/${itemId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json' // Assuming JSON data is sent in the request body
        },
        body: JSON.stringify({
          token: localStorage.getItem('token') // Replace yourStoreIdValue with the actual value of storeId
        })
      });
      if(!response.ok)
        {
          if (response.status === 301) {
            // Token expired
            alert('Session expired,Login Again');
            localStorage.removeItem('token')
            navigate('/');
          } else if (response.status === 302 || response.status === 303) {
            // Invalid token or other client-side error
            alert('Invalid Session, Login Again');
            localStorage.removeItem('token')
            navigate('/');
          } 
        }
      if (response.ok) {
        const dataa = await response.json();
        console.log(dataa)
        localStorage.setItem('token',dataa.token);
        const data=dataa.inventoryItem;
      setItemData(data);
      setLoading(false);
    } }catch (error) {
      console.error('Error fetching item details:', error);
      setError('Failed to fetch item details');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItemDetails();
  }, [itemId]);

  const handleBackClick = () => {
    navigate('/allproducts'); // Navigate back to the inventory table
  };

  const renderFormFields = () => {
    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error: {error}</div>;
    }

    const formFields = [];

    // Include specific fields consistently present in the API responses
    formFields.push(
      <div key="sku">
        <label>SKU</label>
        <input type="text" value={itemData.sku} readOnly />
      </div>
    );

    formFields.push(
      <div key="locale">
        <label>Locale</label>
        <input type="text" value={itemData.locale} readOnly />
      </div>
    );

    formFields.push(
      <div key="title">
        <label>Title</label>
        <input
          type="text"
          value={itemData.product.title}
          readOnly
        />
      </div>
    );

    formFields.push(
      <div key="description">
        <label>Description</label>
        <textarea rows={5}
          value={itemData.product.description}
          readOnly
        />
      </div>
    );

    formFields.push(
      <div key="imageUrls">
        <label>Image URLs</label>
        {itemData.product.imageUrls.map((url, index) => (
          <div key={index}>
            <input type="text" value={url} readOnly />
          </div>
        ))}
      </div>
    );
    
    

    // Include aspects dynamically
    for (const [aspectKey, aspectValue] of Object.entries(itemData.product.aspects)) {
      formFields.push(
        <div key={aspectKey}>
          <label>{aspectKey}</label>
          <input
            type="text"
            value={aspectValue[0]}
            readOnly
          />
        </div>
      );
    }

    formFields.push(
      <div key="condition">
        <label>Condition</label>
        <input
          type="text"
          value={itemData.condition}
          readOnly
        />
      </div>
    );

    formFields.push(
      <div key="conditionDescription">
        <label>Condition Description</label>
        <textarea rows={5}
          value={itemData.conditionDescription}
          readOnly
        />
      </div>
    );

    formFields.push(
      <div key="quantity">
        <label>Quantity</label>
        <input
          type="number"
          value={itemData.availability.shipToLocationAvailability.quantity}
          readOnly
        />
      </div>
    );

    // Add other fields as needed

    return formFields;
  };

  return (
    <div id='tog-sidebar'>
    <div>
      <h2>Edit Item</h2>
      <button className='backButton' onClick={handleBackClick}>Back to Inventory</button>
      {renderFormFields()}
    </div>
    </div>
  );
};
export default ProductDetails;

