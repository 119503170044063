import React, { useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import { Sidebar } from './components/Sidebar';
import ProductListing from "./components/ProductListing/productListing";
import Login from "./components/Login/Login";
import SignUp from "./components/SignUp/SignUp";
import "./App.css";
import ProductTable from "./components/TableProduct/tableProduct";
import ProductDetails from "./components/ProductDetail";
import EditInventoryItem from "./components/EditProduct/editInventoryProduct";
import FulfillmentPolicyForm from "./components/Information/Createfulfillment";
import UpdateFulfillmentPolicyForm from "./components/Information/viewFulfillment Policies";
import ViewReturnPolicyForm from "./components/Information/viewReturnPolicy";
import SelectStoreForm from "./components/Information/selectStore";
import SetDefaultForm from "./components/Information/setDefaults";
import PaymentPolicyForm from "./components/Information/createPaymentPolicy";
import ReturnPolicyForm from "./components/Information/createReturnPolicy";
import BusinessPolicyForm from "./components/Information/createBusinessPolicy";
import LocationPolicyForm from "./components/Information/createLocationPolicy";
import EditDeletePage from "./components/Information/EditDelete";
import ViewPaymentPolicyForm from "./components/Information/viewPaymentPolicies";
import ProtectedRoute from "./PrivateRoute";
import StoreIntegration from "./components/Information/store-integration";


function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

function AppContent() {
    const location = useLocation();

    // Function to check if the current route is the login page
    const isLoginPage = () => {
     
      if(location.pathname === "/" || location.pathname === "/Login" || location.pathname === "/signup")
      {
        return true;
      }
      return false;
    };

  return (
    <div className="App">
     {/* <Sidebar /> */}
     {!isLoginPage() && <Sidebar />}
        
        <Routes>
          {/* Add Home Screen Path */}
          <Route path="/" element={<Login />} />  
          <Route path="/signup" element={<SignUp />} />   
         

          <Route
            path="store-integration"
            element={<SelectStoreForm />}
          />

          <Route path="/" element={<ProtectedRoute/>}>


          {/* Admin Routes*/}         
                    
          <Route
            path="admin/defaultpolicies"
            element={<SetDefaultForm />}
          />

          {/* Store integration */}

          <Route
            path="store"
            element={<StoreIntegration  />}
          />

          {/* Create Policy routes */}

          <Route
            path="admin/createfulfillmentpolicy"
            element={<FulfillmentPolicyForm />}
          />
          <Route
            path="admin/createbusinesspolicy"
            element={<BusinessPolicyForm />}
          />
          <Route
            path="admin/createreturnpolicy"
            element={<ReturnPolicyForm />}
          />
          <Route
            path="admin/createpaymentpolicy"
            element={<PaymentPolicyForm />}
          />
          <Route
            path="admin/createlocation"
            element={<LocationPolicyForm />}
          />

          {/* Edit, View and Delete Policies */}
         
          <Route
            path="admin/viewfulfillment policies/:policyId"
            element={<UpdateFulfillmentPolicyForm />}
          />
          <Route
            path="admin/viewpayment policies/:policyId"
            element={<ViewPaymentPolicyForm />}
          />
            <Route
            path="admin/viewreturn policies/:policyId"
            element={<ViewReturnPolicyForm />}
          />          
       
          <Route
            path="admin/policieslocations/:Id"
            element={<EditDeletePage />}
          />

          

        

          {/* User Routes*/}
          <Route path="allproducts" element={<ProductTable />} />

           {/* Create Product */}
          <Route path="productlisting" element={<ProductListing />} />

         

          {/* View Product */}          
          <Route path="products/:itemId" element={<ProductDetails />} />

          {/* Edit Product */}
          <Route
            path="editproducts/:itemId"
            element={<EditInventoryItem />}
          />            

        </Route>

         
          {/* Sign Up to ThriftOps */}
          <Route path="/signup" element={<SignUp />} />{" "}
        </Routes>
      </div>
 
  );
}

export default App;
