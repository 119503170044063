import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";


const ImageUploader = ({ UploadImages,imageUrls }) => {
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);

  const onDrop = (acceptedFiles) => {
    const updatedImages = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setImages((prevImages) => [...prevImages, ...updatedImages]);
  };

  console.log(images, "images");
  
  const { getRootProps, getInputProps } = useDropzone({ onDrop });



  function handleImagesUpload(e) {
    e.preventDefault();
    if (images.length > 0) {
      setUploading(true);
    } else {
      alert("Please upload images first.");
    }
  }
  useEffect(() => {
    
    if (images?.length > 0 && uploading) {
      let files = Array.from(images);
     
     
      files.forEach(async (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        (async () => {
          try {
              await UploadImages(file);
              setUploading(false);
          } catch (error) {
             
          }
      })();
        
      });

      
    }
  }, [images, uploading]);

  function deleteImage(name) {
    // Remove the image at the specified index from the images array
    let updatedImages = [...images];
    // console.log(updatedImages)
    setImages(updatedImages.filter((img) => img.name !== name));
    // setImages(updatedImages.splice(index, 1));
    // Update the state or re-render the component to reflect the change
    // Example: setState({ images: images });
  }

  return (
    <div className="innerSection images">
                {uploading ? (
                   <div>
        <div className="spinner"></div>
        <p className='loading'>LOADING.....</p>
        <script>
        </script>
       </div>
                  ) : (
                    ""
                  )}
      <p>
        Upload Images{" "}
        {/* <span>
  {" "}
  - Please do not proceed before uploading at least one image
</span> */}
      </p>
      <div className="image-uploader">
        <div {...getRootProps({ className: "dropzone" })}>
          {" "}
          <input {...getInputProps()} id="upload-img" />
          <span>
            <svg
              width="58"
              height="58"
              viewBox="0 0 58 58"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="27.6191"
                width="2.7619"
                height="58"
                rx="1.38095"
                fill="#142B41"
              />
              <rect
                y="30.3809"
                width="2.7619"
                height="58"
                rx="1.38095"
                transform="rotate(-90 0 30.3809)"
                fill="#142B41"
              />
            </svg>
          </span>
        </div>

        {images?.length > 0 && (
          <div className="image-actions">
            {/* <span id="btnClear" onClick={handleClear}>
              {" "}
              <svg
                height="512"
                viewBox="0 0 512 512"
                width="512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m328.96 30.2933333-30.293333-30.2933333-134.186667 134.4-134.1866667-134.4-30.2933333 30.2933333 134.4 134.1866667-134.4 134.186667 30.2933333 30.293333 134.1866667-134.4 134.186667 134.4 30.293333-30.293333-134.4-134.186667z"
                  fill-rule="evenodd"
                  transform="translate(91.52 91.52)"
                />
              </svg>
            </span> */}
            <span
              id="btnUpload"
              onClick={(e) => handleImagesUpload(e)}
              disabled={images.length === 0 || uploading}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <polygon
                  fill="var(--ci-primary-color, currentColor)"
                  points="346.231 284.746 256.039 194.554 165.847 284.746 188.474 307.373 240.039 255.808 240.039 496 272.039 496 272.039 255.808 323.604 307.373 346.231 284.746"
                  class="ci-primary"
                />
                <path
                  fill="var(--ci-primary-color, currentColor)"
                  d="M400,161.453V160c0-79.4-64.6-144-144-144S112,80.6,112,160v2.491A122.285,122.285,0,0,0,49.206,195.2,109.4,109.4,0,0,0,16,273.619c0,31.119,12.788,60.762,36.01,83.469C74.7,379.275,105.338,392,136.07,392H200V360H136.07C89.154,360,48,319.635,48,273.619c0-42.268,35.64-77.916,81.137-81.155L144,191.405V160a112,112,0,0,1,224,0v32.04l15.8.2c46.472.588,80.2,34.813,80.2,81.379C464,322.057,428.346,360,382.83,360H312v32h70.83a109.749,109.749,0,0,0,81.14-35.454C484.625,334.339,496,304.889,496,273.619,496,215.182,455.716,169.392,400,161.453Z"
                  class="ci-primary"
                />
              </svg>
            </span>
            {/* <button >
    Clear All Images
  </button> */}
            {/* {uploading && <p>Uploading...</p>} */}
            {/* <button
    
  >
    {uploading ? "Uploading..." : }
  </button> */}
          </div>
        )}
      </div>
      {/* Display selected images */}
      <div className="preview">
        {images.map((file, index) => (
          <div key={file.name} class="image-container">
            <img src={file.preview} alt="Uploaded" class="preview-image" />
            <div class="delete-icon" onClick={() => deleteImage(file.name)}>
              <img src="/trash.png" alt="delete" className="delete-img" />
            </div>
          </div>
        ))}
        {imageUrls && imageUrls.map((imageUrl, index) => (
        <div key={index} className="image-container">
          <img src={imageUrl} alt="Uploaded" className="preview-image" />
        </div>
      ))}
      </div>
    </div>
  );
};

export default ImageUploader;
