import React, { useEffect, useState } from "react";
import "./ProductBasic.css";
import { useDropzone } from "react-dropzone";
import ImageUploader from "./imageUploader/ImageUploader";

import { useNavigate } from "react-router-dom";

const ProductBasics = ({
  UploadImages,
  onSkip,
  setStep,
  categoryAspects,
  imageURLs,
  step,
  setPrice,
  setTitle
}) => {
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [buttonText, setButtonText] = useState('Autofill Data');
  const navigate = useNavigate();


  const onDrop = (acceptedFiles) => {
    const updatedImages = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setImages((prevImages) => [...prevImages, ...updatedImages]);
  };

  console.log(images, "images");
  const handleClear = () => {
    setImages([]);
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  function handleImagesUpload(e) {
    e.preventDefault();
    if (images.length > 0) {
      setUploading(true);
      
    } else {
      alert("Please upload images first.");
    }
  }
  useEffect(() => {
    if (images?.length > 0 && uploading) {
      let files = Array.from(images);

      files.forEach(async (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        await UploadImages(file);
      });

      setUploading(false);
    }
  }, [images, uploading]);

  // async function buttonEPID() {
  //   //const epid=document.getElementById('epid').textContent;

  //   // //get data using epid
  //   // setStep(step + 1);
  //   const epid = "20049205887";
  //   try {
  //     console.log(epid);
  //     const response = await fetch(`/api/v1/getProduct/${epid}`, {});
  //     if (response.ok) {
  //       const data = await response.json();
  //       console.log("details:", data);
  //     } else {
  //       console.error("Failed", response.status);
  //       alert("EPID not found");
  //     }
  //   } catch (error) {
  //     console.error("Error deleting policy:", error);
  //     // Handle error
  //   }
  // }
  function leaveRestToTeam(){
    if(imageURLs.length!=0)
    {
      setStep(step + 2);
    }
    else{
      alert("Upload atleast one image first");
    }
    
  }
  function buttonAutofill(e,button) {
    if(imageURLs.length!=0)
    {
      button.textContent = 'Autofilling in Progress';
      e.preventDefault();
        function getImgixUrl(originalUrl, width, height) {
          const imgixBaseUrl = 'https://swagkicks-307422320.imgix.net/';
          const url = new URL(originalUrl);
          const path = url.pathname + url.search;
          const transformation = `?w=${width}&h=${height}`;
          return imgixBaseUrl + path + transformation;
      }
      const updated = getImgixUrl(imageURLs[0], 500, 500);
      const aspectNames = categoryAspects.map(
        (aspect) => aspect.localizedAspectName
      );
      console.log(aspectNames);

      const requestData = {
        product_url: updated, // Replace this URL with the actual product URL
        aspects: aspectNames, // Replace with the actual aspects data
        token: localStorage.getItem('token')
      };
      const headers = {
        "Content-Type": "application/json",
      };
      // Define the fetch options
      fetch(
        "/api/v1/autofillingImage",
        {
          method: "POST",
          headers: {
            ...headers,
            'Cache-Control': 'no-cache'
          },
          body: JSON.stringify(requestData)
        }
    )
        .then((response) => {
          if (!response.ok) {
              alert(response.error);
          }
          else
          {
            return response.json();
          }
          
        })
        .then((data) => {
          if(data.error)
            {
              alert(data.error);
            }
            else
            {
          // Handle the response data here
          //console.log(data);
          categoryAspects.aspects = data.data["category_value"];
          setPrice(data.data["price"]);
          setTitle(data.data["title"]);
          //autofill to text boxes here
          setStep(step + 1);    
            }
          
        })
        .catch((error) => {
          // Handle errors here
          console.error("There was a problem with the fetch operation:", error);
          button.textContent = 'Autofill Data';
        });
    }
    else{
      alert("Upload atleast one image first");
    }
   
  }

  // useEffect(() => {
  //   const uploadImages = async () => {
  //     for (const file of images) {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       await UploadImages([file]);
  //     }
  //   };

  //   if (images?.length > 0) {
  //     uploadImages();
  //   }
  // }, [images]);

  return (
    <div className="basic-product-wrapper">
      <div className="basics-product">
        <div className="basics-products-form">
          <p>Product Basics</p>


          <form>
            {/* <div>
              <label htmlFor="upc-code">Enter UPC Code</label>
              <input type="text" name="upc-code" placeholder="349828285529" />
            </div> */}
            {/* <div>
              <label htmlFor="upc-code">Enter Ebay Product ID</label>
              <input type="text" name="epid" id="epid" placeholder="349828285529" />
              <button onClick={buttonEPID}>Get Data</button>
            </div> */}

            <ImageUploader UploadImages={UploadImages} imageUrls={imageURLs} />
          
            <div>
              {/* <input
            hidden
            value={imageURLs}
            {...register("productDetails.product.imageUrls", {
              required: true,
            })}
            // error={formState.errors?.product.imageURLs ? true : false}
          />  */}
              

              {/* <button id="btnSkip" value="Skip" onClick={onSkip}>
                Proceed Entering Manually
              </button>
              <input
                type="text"
                id="epid"
                placeholder="Enter Ebay Product ID"
              ></input>
              <button id="btnEPID" value="EPID" onClick={buttonEPID}>
                Get Data using EPID
          </button> */}
            </div>
          </form>
        </div>
        
      </div>

      <div className="img-basics">
        <div>
          <button id="btnSkip" value="Skip" onClick={onSkip}>
            Skip
          </button>
        </div>

        <div onClick={onSkip}>
          <span>
            <svg
              width="527"
              height="930"
              viewBox="0 0 527 1024"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.09">
                <path
                  d="M526.788 1023.58C806.68 1023.58 1033.58 796.68 1033.58 516.788C1033.58 236.897 806.68 10 526.788 10C246.897 10 20 236.897 20 516.788C20 796.68 246.897 1023.58 526.788 1023.58Z"
                  stroke="#0C7489"
                  strokeOpacity="0.7"
                  strokeWidth="40"
                  strokeMiterlimit="10"
                />
                <path
                  d="M526.789 954.783C768.686 954.783 964.783 758.686 964.783 516.789C964.783 274.891 768.686 78.7939 526.789 78.7939C284.891 78.7939 88.7939 274.891 88.7939 516.789C88.7939 758.686 284.891 954.783 526.789 954.783Z"
                  stroke="#0C7489"
                  strokeOpacity="0.7"
                  strokeWidth="40"
                  strokeMiterlimit="10"
                />
                <path
                  d="M526.788 891.228C733.585 891.228 901.228 723.585 901.228 516.788C901.228 309.991 733.585 142.349 526.788 142.349C319.991 142.349 152.349 309.991 152.349 516.788C152.349 723.585 319.991 891.228 526.788 891.228Z"
                  stroke="#0C7489"
                  strokeOpacity="0.7"
                  strokeWidth="40"
                  strokeMiterlimit="10"
                />
                <path
                  d="M526.789 828.01C698.671 828.01 838.01 688.671 838.01 516.789C838.01 344.906 698.671 205.567 526.789 205.567C354.906 205.567 215.567 344.906 215.567 516.789C215.567 688.671 354.906 828.01 526.789 828.01Z"
                  stroke="#0C7489"
                  strokeOpacity="0.7"
                  strokeWidth="40"
                  strokeMiterlimit="10"
                />
                <path
                  d="M526.789 771.505C667.465 771.505 781.505 657.465 781.505 516.789C781.505 376.113 667.465 262.072 526.789 262.072C386.113 262.072 272.072 376.113 272.072 516.789C272.072 657.465 386.113 771.505 526.789 771.505Z"
                  stroke="#0C7489"
                  strokeOpacity="0.7"
                  strokeWidth="40"
                  strokeMiterlimit="10"
                />
                <path
                  d="M526.789 711.396C634.267 711.396 721.396 624.267 721.396 516.789C721.396 409.31 634.267 322.182 526.789 322.182C419.31 322.182 332.182 409.31 332.182 516.789C332.182 624.267 419.31 711.396 526.789 711.396Z"
                  stroke="#0C7489"
                  strokeOpacity="0.7"
                  strokeWidth="40"
                  strokeMiterlimit="10"
                />
              </g>
            </svg>
          </span>
        </div>
        <div>
          {" "}
          <button
            disabled={uploading}
            onClick={() => {
              if(imageURLs.length!=0)
              {
                setStep(step + 1);
              }
              else{
                alert("Upload atleast one image first");
              }
            }}
          >
            Next
          </button>
          <button
                id="btnAutofillData"
                value="Autofill Data"
                onClick={(e) => buttonAutofill(e,e.target)}
              >
                Autofill Data
              </button>
              <button onClick={leaveRestToTeam}>Leave listing to ThriftOps team</button>
        </div>
      </div>
    </div>
  );
};

export default ProductBasics;
