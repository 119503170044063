import React from "react";
import { Navigate, Outlet } from "react-router-dom";


const ProtectedRoute = () => {
    const companyId = localStorage.getItem('company_id');
    const storeId = localStorage.getItem('store_id');
    const isLoggedIn = !!companyId; // Returns true if company_id exists, false otherwise
    const isStoreSetup = !!storeId; // Returns true if store_id exists, false otherwise

    if (isLoggedIn) {
        if (isStoreSetup) {
            return <Outlet />;
        } else {
            return <Navigate to="/store-integration" />;
        }
    } else {
        return <Navigate to="/" />;
    }
};

export default ProtectedRoute;