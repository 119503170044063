import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./Information.css";
import "./../styles.css";
import axios from 'axios';

const BusinessPolicyForm = () => {
  const [formData, setFormData] = useState({
    programType: "",
  });
  const store_id = localStorage.getItem('store_id');

  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const toggleSelect = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData);

      const response = await axios.post("/api/v1/optinprogram", {
        data: formData,
        store_id: store_id
      });

      console.log("Business policy created successfully:", response.data);
      toast.success("Business policy created successfully!");
      navigate("/admin/defaultpolicies");
    } catch (err) {
      console.error("Error:", err);
      setError("Error creating business policy");
    }
  };

  const buttonStyle = {
    cursor: "pointer",
    height: "60px",
    background: "#142B41",
    boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    color: "#FFFFFF",
    marginTop: "2rem",
  };

  const errorMessageStyle = {
    color: "red",
    margin: "5px 0",
  };

  return (
  <div id="tog-sidebar">
    <div className="business-policy-form">
      <div className="business-policy-form-left">
        <h1>Business Policy</h1>
        <a href="#">Details to opt in to the business policy</a>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras faucibus
          sed erat et tristique. Praesent pharetra mauris magna, eget laoreet
          neque pellentesque id. Quisque varius accumsan purus egestas commodo.
        </p>
        <form onSubmit={handleSubmit}>
          {/* Program Type */}
          <div
            className="custom-select-container"
            style={{ height: expanded ? "250px" : "" }}
          >
            <div className={`custom-select ${expanded ? "active" : ""}`}>
              <button
                type="button"
                className="select-button"
                onClick={toggleSelect}
                aria-expanded={expanded}
              >
                <span className="selected-value">
                  {formData.programType
                    ? formData.programType
                    : "Please select the program type."}
                </span>
                <span className="arrow"></span>
              </button>
           

            <ul className="select-dropdown">
              <li>
                <label htmlFor="programTypeSelectPolicyManagement">
                  <input
                    type="radio"
                    id="programTypeSelectPolicyManagement"
                    name="programType"
                    value="OUT_OF_STOCK_CONTROL"
                    checked={formData.programType === "OUT_OF_STOCK_CONTROL"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        programType: e.target.value,
                      })
                    }
                  />
                  OUT_OF_STOCK_CONTROL
                </label>
              </li>
              <li>
                <label htmlFor="programTypeOthers">
                  <input
                    type="radio"
                    id="programTypeOthers"
                    name="programType"
                    value="PARTNER_MOTORS_DEALER"
                    checked={formData.programType === "PARTNER_MOTORS_DEALER"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        programType: e.target.value,
                      })
                    }
                  />
                  PARTNER_MOTORS_DEALER
                </label>
              </li>
              <li>
                <label htmlFor="programTypeOthers">
                  <input
                    type="radio"
                    id="programTypeOthers"
                    name="programType"
                    value="SELLING_POLICY_MANAGEMENT"
                    checked={formData.programType === "SELLING_POLICY_MANAGEMENT"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        programType: e.target.value,
                      })
                    }
                  />
                  SELLING_POLICY_MANAGEMENT
                </label>
              </li>
            </ul>

            </div>
          </div>

          <button className="next-btn" type="submit" style={buttonStyle}>
            Save
          </button>
          {error && (
            <p className="error-message" style={errorMessageStyle}>
              {error}
            </p>
          )}
        </form>
      </div>
    </div>
    </div>
  );
};

export default BusinessPolicyForm;
