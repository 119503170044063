import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate,useParams  } from "react-router-dom";
import "./Information.css";
import "./../styles.css";
import axios from "axios";

const ViewFulfillmentPolicyForm = () => {
  const token = localStorage.getItem('token');
  const [formData, setFormData] = useState(null);
  const [error, setError] = useState(null);
  const [expandedMarketplaceId, setExpandedMarketplaceId] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expandedTimeUnit, setExpandedTimeUnit] = useState(false);
  const navigate = useNavigate();
  const { policyId } = useParams();

  const toggleSelectMarketplaceId = () => {
    setExpandedMarketplaceId((prevExpanded) => !prevExpanded);
  };

  const toggleSelect = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const toggleSelectTimeUnit = () => {
    setExpandedTimeUnit((prevexpandedTimeUnit) => !prevexpandedTimeUnit);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData);
      const response = await fetch(`/api/v1/updateFulfillmentPolicy/${policyId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: formData,
          token: token
        })
      });
      console.log("waiting for response");
      if(!response.ok)
        {
          if (response.status === 301) {
            // Token expired
            alert('Session expired,Login Again');
            localStorage.removeItem('token')
            navigate('/');
          } else if (response.status === 302 || response.status === 303) {
            // Invalid token or other client-side error
            alert('Invalid Session, Login Again');
            localStorage.removeItem('token')
            navigate('/');
          } 
        }
      // console.log("After response response status: ", response.ok);
      // console.log(response.json);
      const data = await response.json();
      
      localStorage.setItem('token', data.token)
    

      console.log("Fulfillment policy created successfully:", response.data);
      toast.success("Fulfillment policy created successfully!");
      navigate("/Admin/policieslocations/FulfillmentPolicy");
    } catch (err) {
      console.error('Error:', err);
      setError('Error updating fulfillment policy');
    }
  };

  useEffect(() => {
    // Fetch existing policy details using policyId
    const fetchPolicyDetails = async () => {
      try {
        const response = await fetch(`/api/v1/viewFulfillmentPolicy/${policyId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: token
          })
        });
        console.log("waiting for response");
        if(!response.ok)
          {
            if (response.status === 301) {
              // Token expired
              alert('Session expired,Login Again');
              localStorage.removeItem('token')
              navigate('/');
            } else if (response.status === 302 || response.status === 303) {
              // Invalid token or other client-side error
              alert('Invalid Session, Login Again');
              localStorage.removeItem('token')
              navigate('/');
            } 
          }
        // console.log("After response response status: ", response.ok);
        // console.log(response.json);
        const data = await response.json();
        
        localStorage.setItem('token', data.token)
        console.log(data.data);
        setFormData(data.data);
        
      } catch (error) {
        console.error('Error fetching policy details:', error);
        setError('Error fetching policy details');
      }
    };

    fetchPolicyDetails();
  }, [policyId]);

  const inputStyle = {
    width: "100%",
    height: "60px",
    background: "#FFFFFF",
    border: "none",
    boxShadow: "0px 5px 5px 0px #00000040",
    borderRadius: "10px",
    marginBottom: "3rem",
    padding: "1rem",
    boxSizing: "border-box",
  };

  const buttonStyle = {
    cursor: "pointer",
    height: "60px",
    background: "#142B41",
    boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    color: "#FFFFFF",
    marginTop: "2rem",
  };

  const checkBoxStyle = {
    background: "#FFFFFF",
    // boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    border: "none",
    outline: "none",
    cursor: "pointer",
  };

  const errorMessageStyle = {
    color: "red",
    margin: "5px 0",
  };

  return (
    <div id="tog-sidebar">
    <div className="fulfillment-policy-form">

      <div className="fulfillment-policy-form-left">
      {formData ? ( 
        <div>
      
          
        
        <h1>Fulfillment Policy</h1>
        <form onSubmit={handleSubmit}>
          {/* Category Types */}
          <label htmlFor="categoryType">Category Type Name</label>
          <input
            type="text"
            id="categoryType"
            name="name"
            value={formData.categoryTypes[0].name}
            placeholder="Please enter the category type."
            onChange={(e) =>
              setFormData({
                ...formData,
                categoryTypes: [{ name: e.target.value }],
              })
            }
            style={inputStyle}
          />

         {/* Marketplace ID */}
        <div
          className="custom-select-container"
          style={{ height: expandedMarketplaceId ? "300px" : "" }}
        >
          <label htmlFor="marketplaceId">Marketplace ID</label>
          <div
            className={`custom-select ${
              expandedMarketplaceId ? "active" : ""
            }`}
          >
            <button
              type="button"
              className="select-button"
              onClick={toggleSelectMarketplaceId}
              aria-expanded={expandedMarketplaceId}
            >
              <span
                className={`selected-value ${
                  formData.marketplaceId !== "" &&
                  formData.marketplaceId !== "Select"
                    ? "selected-black"
                    : ""
                }`}
              >
                {formData.marketplaceId === ""
                  ? "Select"
                  : formData.marketplaceId === "Ebay US"
                  ? "Ebay US"
                  : formData.marketplaceId === "Ebay CA" // Check if it's "Ebay CA" from API
                  ? "Ebay CA"
                  : formData.marketplaceId} {/* Display actual value if not "Ebay US" or "Ebay CA" */}
              </span>
              <span className="arrow"></span>
            </button>
            <ul className="select-dropdown">
              <li>
                <label htmlFor="marketplaceIdEBAY_US">
                  <input
                    type="radio"
                    id="marketplaceIdEBAY_US"
                    name="marketplaceId"
                    value="Ebay US"
                    checked={formData.marketplaceId === "Ebay US"}
                    onChange={() =>
                      setFormData({ ...formData, marketplaceId: "Ebay US" })
                    }
                  />
                  Ebay US
                </label>
              </li>
              <li>
                <label htmlFor="marketplaceIdEbayCA">
                  <input
                    type="radio"
                    id="marketplaceIdEbayCA"
                    name="marketplaceId"
                    value="Ebay CA"
                    checked={formData.marketplaceId === "Ebay CA"}
                    onChange={() =>
                      setFormData({ ...formData, marketplaceId: "Ebay CA" })
                    }
                  />
                  Ebay CA
                </label>
              </li>
            </ul>
          </div>
        </div>



          {/* Name */}
          <label htmlFor="name">Fulfillment Name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Please enter the fulfillment name."
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            style={inputStyle}
          />

          {/* Global Shipping */}

          {/* old */}

          {/* <label htmlFor="globalShipping">Global Fulfillment</label> */}
          {/* <div className="radio-inline">
            <label
              htmlFor="globalShippingTrue"
              style={{ display: "inline-block", marginRight: "10px" }}
            >
              <input
                type="radio"
                id="globalShippingTrue"
                name="globalShipping"
                value="true"
                checked={formData.globalShipping === "true"}
                onChange={() =>
                  setFormData({ ...formData, globalShipping: "true" })
                }
              />
              Enabled
            </label>
            <label
              htmlFor="globalShippingFalse"
              style={{ display: "inline-block", marginRight: "10px" }}
            >
              <input
                type="radio"
                id="globalShippingFalse"
                name="globalShipping"
                value="false"
                checked={formData.globalShipping === "false"}
                onChange={() =>
                  setFormData({ ...formData, globalShipping: "false" })
                }
              />
              Disabled
            </label>
          </div> */}

          <div
            className="custom-select-container"
            style={{ height: expanded ? "300px" : "" }}
          >
            <label htmlFor="globalShipping">Global Shipping</label>
            <div className={`custom-select ${expanded ? "active" : ""}`}>
              <button
                type="button"
                className="select-button"
                onClick={toggleSelect}
                aria-expanded={expanded}
              >
                <span
                  className={`selected-value ${
                    formData.globalShipping !== "" &&
                    formData.globalShipping !== "Select"
                      ? "selected-black"
                      : ""
                  }`}
                >
                  {formData.globalShipping === ""
                    ? "Select"
                    : formData.globalShipping === "true"
                    ? "Enabled"
                    : "Disabled"}
                </span>
                <span className="arrow"></span>
              </button>
              <ul className="select-dropdown">
                <li>
                  <label htmlFor="globalShippingTrue">
                    <input
                      type="radio"
                      id="globalShippingTrue"
                      name="globalShipping"
                      value="true"
                      checked={formData.globalShipping === "true"}
                      onChange={() =>
                        setFormData({ ...formData, globalShipping: "true" })
                      }
                    />
                    Enabled
                  </label>
                </li>
                <li>
                  <label htmlFor="globalShippingFalse">
                    <input
                      type="radio"
                      id="globalShippingFalse"
                      name="globalShipping"
                      value="false"
                      checked={formData.globalShipping === "false"}
                      onChange={() =>
                        setFormData({ ...formData, globalShipping: "false" })
                      }
                    />
                    Disabled
                  </label>
                </li>
              </ul>
            </div>
          </div>

          {/* Handling Time */}

          {/* old */}

          {/* <fieldset>
            <legend>Handling Time Value</legend>
            <label htmlFor="handlingTimeUnit">Handling Time Unit</label>
            <input
              type="text"
              id="handlingTimeUnit"
              name="unit"
              value={formData.handlingTime.unit}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  handlingTime: {
                    ...formData.handlingTime,
                    unit: e.target.value,
                  },
                })
              }
              style={inputStyle}
            />
            <label htmlFor="handlingTimeValue">Value:</label>
            <input
              type="text"
              id="handlingTimeValue"
              name="value"
              value={formData.handlingTime.value}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  handlingTime: {
                    ...formData.handlingTime,
                    value: e.target.value,
                  },
                })
              }
              style={inputStyle}
            />
          </fieldset> */}

          {/* Handling Time Value */}
          <label htmlFor="handlingTimeValue">Handling Time Value</label>
          <input
            type="text"
            id="handlingTimeValue"
            name="value"
            placeholder="Please enter the handling time value."
            value={formData.handlingTime.value}
            onChange={(e) =>
              setFormData({
                ...formData,
                handlingTime: {
                  ...formData.handlingTime,
                  value: e.target.value,
                },
              })
            }
            style={inputStyle}
          />

          {/* Handling Time Unit */}
          <div
            className="custom-select-container"
            style={{ height: expandedTimeUnit ? "350px" : "" }}
          >
            <label htmlFor="handlingTimeUnit">Handling Time Unit</label>
            <div
              className={`custom-select ${expandedTimeUnit ? "active" : ""}`}
            >
              <button
                type="button"
                className="select-button"
                onClick={toggleSelectTimeUnit}
                aria-expanded={expandedTimeUnit}
              >
                <span
                  className={`selected-value ${
                    formData.handlingTime.unit !== "" &&
                    formData.handlingTime.unit !== "Select"
                      ? "selected-black"
                      : ""
                  }`}
                >
                  {formData.handlingTime.unit || "Select"}
                </span>
                <span className="arrow"></span>
              </button>
              <ul className="select-dropdown">
                <li>
                  <label htmlFor="day">
                    <input
                      type="radio"
                      id="day"
                      name="handlingTimeUnit"
                      value="Day"
                      checked={formData.handlingTime.unit === "Day"}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          handlingTime: {
                            ...formData.handlingTime,
                            unit: e.target.value,
                          },
                        });
                      }}
                    />
                    Day
                  </label>
                </li>
                <li>
                  <label htmlFor="week">
                    <input
                      type="radio"
                      id="week"
                      name="handlingTimeUnit"
                      value="Week"
                      checked={formData.handlingTime.unit === "Week"}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          handlingTime: {
                            ...formData.handlingTime,
                            unit: e.target.value,
                          },
                        });
                      }}
                    />
                    Week
                  </label>
                </li>
                <li>
                  <label htmlFor="month">
                    <input
                      type="radio"
                      id="month"
                      name="handlingTimeUnit"
                      value="Month"
                      checked={formData.handlingTime.unit === "Month"}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          handlingTime: {
                            ...formData.handlingTime,
                            unit: e.target.value,
                          },
                        });
                      }}
                    />
                    Month
                  </label>
                </li>
              </ul>
            </div>
          </div>

          {/* Shipping Options */}
          {formData.shippingOptions.map((option, index) => (
            <div key={index}>
              <label htmlFor="sippingType">
                Choose Shipping Type{index + 1}
              </label>

              {/* Cost Type */}
              <label htmlFor={`costType${index}`}>Cost Type</label>
              <input
                type="text"
                id={`costType${index}`}
                name={`costType${index}`}
                value={option.costType}
                placeholder={`Please enter the cost type for shipping.`}
                onChange={(e) => {
                  const updatedShippingOptions = [...formData.shippingOptions];
                  updatedShippingOptions[index].costType = e.target.value;
                  setFormData({
                    ...formData,
                    shippingOptions: updatedShippingOptions,
                  });
                }}
                style={inputStyle}
              />

              {/* Option Type */}
              <label htmlFor={`optionType${index}`}>Option Type</label>
              <input
                type="text"
                id={`optionType${index}`}
                name={`optionType${index}`}
                value={option.optionType}
                placeholder={`Please enter the option type for shipping.`}
                onChange={(e) => {
                  const updatedShippingOptions = [...formData.shippingOptions];
                  updatedShippingOptions[index].optionType = e.target.value;
                  setFormData({
                    ...formData,
                    shippingOptions: updatedShippingOptions,
                  });
                }}
                style={inputStyle}
              />

              {/* Shipping Services */}
              {option.shippingServices.map((service, serviceIndex) => (
                <div key={serviceIndex}>
                  <label htmlFor="shippingServices">Shipping Services</label>

                  {/* Buyer Responsible */}
                  <label htmlFor="buyerResponsibleForShipping">
                    Buyer Responsible for Shipping
                  </label>
                  <div className="checkbox-inline">
                    <label htmlFor={`buyerResponsibleForShippingTrue${index}`}>
                      Check if buyer is responsible for shipping.
                    </label>
                    <input
                      type="checkbox"
                      id={`buyerResponsibleForShippingTrue${index}`}
                      name={`buyerResponsibleForShipping${index}`}
                      value="true"
                      checked={service.buyerResponsibleForShipping === "true"}
                      onChange={() => {
                        const updatedShippingOptions = [
                          ...formData.shippingOptions,
                        ];
                        const updatedService = {
                          ...updatedShippingOptions[index].shippingServices[
                            serviceIndex
                          ],
                        };
                        updatedService.buyerResponsibleForShipping =
                          updatedService.buyerResponsibleForShipping === "true"
                            ? "false"
                            : "true";
                        updatedShippingOptions[index].shippingServices[
                          serviceIndex
                        ] = updatedService;
                        setFormData({
                          ...formData,
                          shippingOptions: updatedShippingOptions,
                        });
                      }}
                      style={checkBoxStyle}
                    />
                  </div>

                  {/* Free Shipping */}
                  <label htmlFor="freeShipping">Free Shipping</label>
                  <div className="checkbox-inline">
                    <label htmlFor={`freeShippingTrue${index}`}>
                      Check if buyer is responsible for shipping.
                    </label>
                    <input
                      type="checkbox"
                      id={`freeShippingTrue${index}`}
                      name={`freeShipping${index}`}
                      value="true"
                      checked={service.freeShipping === "true"}
                      onChange={() => {
                        const updatedShippingOptions = [
                          ...formData.shippingOptions,
                        ];
                        const updatedService = {
                          ...updatedShippingOptions[index].shippingServices[
                            serviceIndex
                          ],
                        };
                        updatedService.freeShipping =
                          updatedService.freeShipping === "true"
                            ? "false"
                            : "true";
                        updatedShippingOptions[index].shippingServices[
                          serviceIndex
                        ] = updatedService;
                        setFormData({
                          ...formData,
                          shippingOptions: updatedShippingOptions,
                        });
                      }}
                      style={checkBoxStyle}
                    />
                  </div>

                  {/* Shipping Carrier Code */}
                  <label htmlFor={`shippingCarrierCode${index}`}>
                    Shipping Carrier Code
                  </label>
                  <input
                    type="text"
                    id={`shippingCarrierCode${index}`}
                    name={`shippingCarrierCode${index}`}
                    value={service.shippingCarrierCode}
                    placeholder="Please enter the carrier code for shipping."
                    onChange={(e) => {
                      const updatedShippingOptions = [
                        ...formData.shippingOptions,
                      ];
                      const updatedService = {
                        ...updatedShippingOptions[index].shippingServices[
                          serviceIndex
                        ],
                      };
                      updatedService.shippingCarrierCode = e.target.value;
                      updatedShippingOptions[index].shippingServices[
                        serviceIndex
                      ] = updatedService;
                      setFormData({
                        ...formData,
                        shippingOptions: updatedShippingOptions,
                      });
                    }}
                    style={inputStyle}
                  />

                  {/* Shipping Service Code */}
                  <label htmlFor={`shippingServiceCode${index}`}>
                    Shipping Service Code
                  </label>
                  <input
                    type="text"
                    id={`shippingServiceCode${index}`}
                    name={`shippingServiceCode${index}`}
                    value={service.shippingServiceCode}
                    placeholder="Please enter the service code for shipping."
                    onChange={(e) => {
                      const updatedShippingOptions = [
                        ...formData.shippingOptions,
                      ];
                      const updatedService = {
                        ...updatedShippingOptions[index].shippingServices[
                          serviceIndex
                        ],
                      };
                      updatedService.shippingServiceCode = e.target.value;
                      updatedShippingOptions[index].shippingServices[
                        serviceIndex
                      ] = updatedService;
                      setFormData({
                        ...formData,
                        shippingOptions: updatedShippingOptions,
                      });
                    }}
                    style={inputStyle}
                  />

                  {/* Currency */}
                  <label htmlFor={`currency${index}`}>Currency</label>
                  <input
                    type="text"
                    id={`currency${index}`}
                    name={`currency${index}`}
                    value={service.shippingCost?.currency}
                    placeholder="Please enter the currency for shipping."
                    onChange={(e) => {
                      const updatedShippingOptions = [
                        ...formData.shippingOptions,
                      ];
                      const updatedService = {
                        ...updatedShippingOptions[index].shippingServices[
                          serviceIndex
                        ],
                      };
                      updatedService.shippingCost.currency = e.target.value;
                      updatedShippingOptions[index].shippingServices[
                        serviceIndex
                      ] = updatedService;
                      setFormData({
                        ...formData,
                        shippingOptions: updatedShippingOptions,
                      });
                    }}
                    style={inputStyle}
                  />

                  {/* Shipping Cost Value */}
                  <label htmlFor={`shippingCostValue${index}`}>
                    Shipping Cost Value
                  </label>
                  <input
                    type="text"
                    id={`shippingCostValue${index}`}
                    name={`shippingCostValue${index}`}
                    value={service.shippingCost?.value}
                    placeholder="Please enter the shipping cost value."
                    onChange={(e) => {
                      const updatedShippingOptions = [
                        ...formData.shippingOptions,
                      ];
                      const updatedService = {
                        ...updatedShippingOptions[index].shippingServices[
                          serviceIndex
                        ],
                      };
                      updatedService.shippingCost.value = e.target.value;
                      updatedShippingOptions[index].shippingServices[
                        serviceIndex
                      ] = updatedService;
                      setFormData({
                        ...formData,
                        shippingOptions: updatedShippingOptions,
                      });
                    }}
                    style={inputStyle}
                  />
                </div>
              ))}
            </div>
          ))}

          <button className="next-btn" type="submit" style={buttonStyle}>
            Save
          </button>
          {error && (
            <p className="error-message" style={errorMessageStyle}>
              {error}
            </p>
          )}
        </form>
        </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
    </div>
  );
};

export default ViewFulfillmentPolicyForm;
