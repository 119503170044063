import React from "react";
import "./ProductListCard.css";

const ProductListCard = ({ handleBackButtonClick }) => {
  return (
    <div className="product-list-card-wrapper">
     

      <div className="card-text">
        <p>Thank you very much! We’ll take it from here.</p>
        <p>You can come back after 2 days to check your Product Listing</p>
      </div>
      <div>
        <span>
          <svg
            width="527"
            height="930"
            viewBox="0 0 527 1024"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.09">
              <path
                d="M526.788 1023.58C806.68 1023.58 1033.58 796.68 1033.58 516.788C1033.58 236.897 806.68 10 526.788 10C246.897 10 20 236.897 20 516.788C20 796.68 246.897 1023.58 526.788 1023.58Z"
                stroke="#0C7489"
                strokeOpacity="0.7"
                strokeWidth="40"
                strokeMiterlimit="10"
              />
              <path
                d="M526.789 954.783C768.686 954.783 964.783 758.686 964.783 516.789C964.783 274.891 768.686 78.7939 526.789 78.7939C284.891 78.7939 88.7939 274.891 88.7939 516.789C88.7939 758.686 284.891 954.783 526.789 954.783Z"
                stroke="#0C7489"
                strokeOpacity="0.7"
                strokeWidth="40"
                strokeMiterlimit="10"
              />
              <path
                d="M526.788 891.228C733.585 891.228 901.228 723.585 901.228 516.788C901.228 309.991 733.585 142.349 526.788 142.349C319.991 142.349 152.349 309.991 152.349 516.788C152.349 723.585 319.991 891.228 526.788 891.228Z"
                stroke="#0C7489"
                strokeOpacity="0.7"
                strokeWidth="40"
                strokeMiterlimit="10"
              />
              <path
                d="M526.789 828.01C698.671 828.01 838.01 688.671 838.01 516.789C838.01 344.906 698.671 205.567 526.789 205.567C354.906 205.567 215.567 344.906 215.567 516.789C215.567 688.671 354.906 828.01 526.789 828.01Z"
                stroke="#0C7489"
                strokeOpacity="0.7"
                strokeWidth="40"
                strokeMiterlimit="10"
              />
              <path
                d="M526.789 771.505C667.465 771.505 781.505 657.465 781.505 516.789C781.505 376.113 667.465 262.072 526.789 262.072C386.113 262.072 272.072 376.113 272.072 516.789C272.072 657.465 386.113 771.505 526.789 771.505Z"
                stroke="#0C7489"
                strokeOpacity="0.7"
                strokeWidth="40"
                strokeMiterlimit="10"
              />
              <path
                d="M526.789 711.396C634.267 711.396 721.396 624.267 721.396 516.789C721.396 409.31 634.267 322.182 526.789 322.182C419.31 322.182 332.182 409.31 332.182 516.789C332.182 624.267 419.31 711.396 526.789 711.396Z"
                stroke="#0C7489"
                strokeOpacity="0.7"
                strokeWidth="40"
                strokeMiterlimit="10"
              />
            </g>
          </svg>
        </span>
      </div>
    </div>
  );
};

export default ProductListCard;
