import React, { useState, useEffect } from 'react';
import { useParams, Link,useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import the confirmAlert module for confirmation popup
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import CSS for react-confirm-alert
import "./Information.css";
import axios from 'axios';
import "./../styles.css"


const EditDeletePage = ({ match }) => {
  const navigate = useNavigate();
  const store_id = localStorage.getItem('store_id');
  const [storeInfo, setStoreInfo] = useState(null);
  const { Id } = useParams();

  useEffect(() => {
    async function fetchStoreInfo() {
      try {
       
  
        const token = localStorage.getItem('token');
     

        // Fetch data from your server API endpoint (replace with your actual API endpoint)
        const response = await fetch('/api/v1/storeInfo', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: token
          })
        });
        console.log("waiting for response");
        if(!response.ok)
          {
            if (response.status === 301) {
              // Token expired
              alert('Session expired,Login Again');
              localStorage.removeItem('token')
              navigate('/');
            } else if (response.status === 302 || response.status === 303) {
              // Invalid token or other client-side error
              alert('Invalid Session, Login Again');
              localStorage.removeItem('token')
              navigate('/');
            } 
          }
        console.log("After response response status: ", response.ok);
        const data = await response.json();
        
        localStorage.setItem('token', data.token)
        console.log(data.data);
        setStoreInfo(data.data);
        
        console.log("store data", storeInfo);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    fetchStoreInfo();
  }, []);

  const getPolicyData = () => {
    switch (Id) {
      case 'PaymentPolicy':
        console.log(storeInfo?.paymentPolicies.paymentPolicies);
        return storeInfo?.paymentPolicies.paymentPolicies;

      case 'FulfillmentPolicy':
        return storeInfo?.fulfillmentPolicies.fulfillmentPolicies;
      case 'ReturnPolicy':
        return storeInfo?.returnPolicies.returnPolicies;
      case 'Location':
        return storeInfo?.locations.locations;
      default:
        return null;
    }
  };

  const getHeading = () => {
    switch (Id) {
      case 'PaymentPolicy':
        return 'Payment Policies';
      case 'FulfillmentPolicy':
        return 'Fulfillment Policies';
      case 'ReturnPolicy':
        return 'Return Policies';
      case 'Location':
        return 'Locations';
      default:
        return '';
    }
  };

  const getPolicyId = (policy, policyType) => {
    console.log(policy);
    switch (policyType) {
      case 'Payment Policies':
        return policy.paymentPolicyId;
      case 'Fulfillment Policies':
        console.log(policy.fulfillmentPolicyId);
        return policy.fulfillmentPolicyId;
      case 'Return Policies':
        return policy.returnPolicyId;
      case 'Locations':
        console.log(policy.location.locationId);
        return policy.location.locationId;
      default:
        return null;
    }
  };

  const handleDelete = (policyId, policyType) => {
    console.log(policyId);
    let endpoint;
    switch (policyType) {
      case 'Payment Policies':
        endpoint = `/api/v1/deletePaymentPolicy/${policyId}`;
        break;
      case 'Fulfillment Policies':
        endpoint = `/api/v1/deleteFulfillmentPolicy/${policyId}`;
        break;
      case 'Return Policies':
        endpoint = `/api/v1/deleteReturnPolicy/${policyId}`;
        break;
      case 'Locations':
        endpoint = `/api/v1/deleteLocation/${policyId}`;
        break;
      default:
        console.error('Invalid policy type');
        return;
    }
  
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this policy?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              window.location.reload();
              const token = localStorage.getItem('token');
              const response = await fetch(endpoint,{
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  token: token
                })
              });
              console.log("waiting for response");
                if(!response.ok)
                  {
                    if (response.status === 301) {
                      // Token expired
                      alert('Session expired,Login Again');
                      localStorage.removeItem('token')
                      navigate('/');
                    } else if (response.status === 302 || response.status === 303) {
                      // Invalid token or other client-side error
                      alert('Invalid Session, Login Again');
                      localStorage.removeItem('token')
                      navigate('/');
                    } 
                  }
                console.log("After response, response status: ", response.ok);
                const data = await response.json();
                localStorage.setItem('token', data.token)
                console.log(`${policyType} deleted successfully:`, data.data);
                alert("Policy Id Deleted Successfully");
                // window.location.reload();
            } catch (error) {
              alert("Policy Id Deleted masle");
              console.error('Error deleting policy:', error);
              // Handle error
            }
          }
        },
        {
          label: 'No',
          onClick: () => {} // Do nothing if user cancels
        }
      ]
    });
  };
  

  const buttonStyle = {
    cursor: "pointer",
    height: "30px",
    background: "#142B41",
    boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    color: "#FFFFFF",
    minWidth: "100px",
  };

  return (
<div id='tog-sidebar'>
    <div className="main-container">
      {storeInfo ? (
        <div className="business-policy-form">
          <div className="">
            <h1>{getHeading()}</h1>
            <div className="create-product" style={{ width: "200px"}}>
            <Link to={`/Admin/create${Id.replace(/\b\t?Policy\b/, '').toLowerCase()}`}>
            <button>Create New</button>
          </Link>
        </div>
        <br/>
            <div className="">
              {getPolicyData() && getPolicyData().length > 0 ? (
                <ul style={{ display: 'flex', flexDirection: 'column', gap: '1rem', margin: 0, padding: 0 }}>
                  {getPolicyData().map(policy => (
                    <li key={policy.policyId} style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                      <div style={{ flex: 1 }}>{policy.name}</div>
                      {/* Buttons for CRUD operations */}
                      <div style={{ display: 'flex', gap: '1rem' }}>
                        <Link to={`/admin/view${getHeading()}/${getPolicyId(policy, getHeading())}`}><button className="next-btn" style={buttonStyle}>View</button></Link>
                        <button onClick={() => handleDelete(getPolicyId(policy, getHeading()),getHeading())} className="next-btn" style={buttonStyle}>Delete</button>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No {getHeading()}</p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
        <div className="spinner"></div>
        <p className='loading'>LOADING.....</p>
       </div>
      )}

      <svg
        width="628"
        height="1356"
        viewBox="0 0 628 1356"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.05">
          <path
            d="M644.788 1349.2C989.849 1349.2 1269.58 1048.74 1269.58 678.099C1269.58 307.461 989.849 7 644.788 7C299.727 7 20 307.461 20 678.099C20 1048.74 299.727 1349.2 644.788 1349.2Z"
            stroke="#0C7489"
            stroke-opacity="0.7"
            stroke-width="40"
            stroke-miterlimit="10"
          />
          <path
            d="M644.789 1258.1C943.01 1258.1 1184.77 998.424 1184.77 678.098C1184.77 357.773 943.01 98.0977 644.789 98.0977C346.568 98.0977 104.812 357.773 104.812 678.098C104.812 998.424 346.568 1258.1 644.789 1258.1Z"
            stroke="#0C7489"
            stroke-opacity="0.7"
            stroke-width="40"
            stroke-miterlimit="10"
          />
          <path
            d="M644.788 1173.94C899.736 1173.94 1106.41 951.943 1106.41 678.099C1106.41 404.254 899.736 182.259 644.788 182.259C389.84 182.259 183.165 404.254 183.165 678.099C183.165 951.943 389.84 1173.94 644.788 1173.94Z"
            stroke="#0C7489"
            stroke-opacity="0.7"
            stroke-width="40"
            stroke-miterlimit="10"
          />
          <path
            d="M644.789 1090.22C856.692 1090.22 1028.47 905.709 1028.47 678.099C1028.47 450.488 856.692 265.974 644.789 265.974C432.885 265.974 261.103 450.488 261.103 678.099C261.103 905.709 432.885 1090.22 644.789 1090.22Z"
            stroke="#0C7489"
            stroke-opacity="0.7"
            stroke-width="40"
            stroke-miterlimit="10"
          />
          <path
            d="M644.789 1015.4C818.22 1015.4 958.813 864.385 958.813 678.099C958.813 491.813 818.22 340.798 644.789 340.798C471.358 340.798 330.765 491.813 330.765 678.099C330.765 864.385 471.358 1015.4 644.789 1015.4Z"
            stroke="#0C7489"
            stroke-opacity="0.7"
            stroke-width="40"
            stroke-miterlimit="10"
          />
          <path
            d="M644.789 935.801C777.292 935.801 884.708 820.424 884.708 678.099C884.708 535.774 777.292 420.396 644.789 420.396C512.285 420.396 404.87 535.774 404.87 678.099C404.87 820.424 512.285 935.801 644.789 935.801Z"
            stroke="#0C7489"
            stroke-opacity="0.7"
            stroke-width="40"
            stroke-miterlimit="10"
          />
        </g>
      </svg>

    </div>
    </div>
  );
};

export default EditDeletePage;
