export const marketplaceId = [
  {
    value: 'EBAY_US',
    label: 'EBAY US',
  },
  {
    value: 'EBAY_MOTORS',
    label: 'EBAY US Motors',
  },
  {
    value: 'EBAY_CA',
    label: 'EBAY Canada',
  },
  {
    value: 'EBAY_GB',
    label: 'EBAY UK',
  },
  {
    value: 'EBAY_AU',
    label: 'EBAY Australia',
  },
  {
    value: 'EBAY_AT',
    label: 'EBAY Austria',
  },
  {
    value: 'EBAY_BE',
    label: 'EBAY Belgium (Dutch)',
  },
  {
    value: 'EBAY_FR',
    label: 'EBAY France',
  },
];

export const conditionOptions = [
  {
    value: 'NEW',
    label: 'New',
    conditionId: '1000',
    description: 'A brand-new, unused, unopened, unworn, undamaged item. ',
  },
  {
    value: 'LIKE_NEW',
    label: 'Like New',
    conditionId: '2750',
    description: `An item that looks as if it was just taken out of shrink wrap. No visible wear, and all facets of the item are flawless and intact.`,
  },
  {
    value: 'NEW_OTHER',
    label: 'New Other/New Without Tags',
    conditionId: '1500',
    description:
      'A brand-new new, unused item with no signs of wear. Packaging may be missing or opened. The item may be a factory second or have defects.',
  },
  {
    value: 'NEW_WITH_DEFECTS',
    label: 'New with defects',
    conditionId: '1750',
    description:
      'A brand-new, unused, and unworn item. The item may have cosmetic defects, and/or may contain mismarked tags (e.g., incorrect size tags from the manufacturer). Packaging may be missing or opened. The item may be a new factory second or irregular.',
  },
  {
    value: 'MANUFACTURER_REFURBISHED',
    label: 'MANUFACTURER_REFURBISHED',
  },
  {
    value: 'CERTIFIED_REFURBISHED',
    label: 'Certified - Refurbished',
    conditionId: '2000',
    description:
      'The item is in a pristine, like-new condition. It has been professionally inspected, cleaned, and refurbished by the manufacturer or a manufacturer-approved vendor to meet manufacturer specifications. The item will be in new packaging with original or new accessories.',
  },
  {
    value: 'EXCELLENT_REFURBISHED',
    label: 'Excellent - Refurbished',
    conditionId: '2010',
    description: `The item is in like-new condition, backed by a one year warranty. It has been professionally refurbished, inspected, and cleaned to excellent condition by qualified sellers. The item includes original or new accessories and will come in new generic packaging. See the seller's listing for full details.`,
  },
  {
    value: 'VERY_GOOD_REFURBISHED',
    label: 'Very Good - Refurbished',
    conditionId: '2020',
    description: `The item shows minimal wear and is backed by a one year warranty. It is fully functional and has been professionally refurbished, inspected, and cleaned to very good condition by qualified sellers. The item includes original or new accessories and will come in new generic packaging. See the seller's listing for full details.`,
  },
  {
    value: 'GOOD_REFURBISHED',
    label: 'Good - Refurbished',
    conditionId: '2030',
    description: `The item shows moderate wear and is backed by a one year warranty. It is fully functional and has been professionally refurbished, inspected, and cleaned to good condition by qualified sellers. The item includes original or new accessories and will come in a new generic packaging. See the seller's listing for full details.`,
  },
  {
    value: 'SELLER_REFURBISHED',
    label: 'Seller - Refurbished',
    conditionId: '2500',
    description: `The item was inspected, cleaned, and repaired to full working order and is in excellent condition. This item may or may not be in original packaging.`,
  },
  {
    value: 'USED_EXCELLENT',
    label: 'Used/Pre-owned',
    conditionId: '3000',
    description: `An item that has been used previously. The item may have some signs of cosmetic wear, but is fully operational and functions as intended. This item may be a floor model or store return that has been used.`,
  },
  {
    value: 'USED_VERY_GOOD',
    label: 'Very Good',
    conditionId: '4000',
    description: `An item that is used but still in very good condition. No obvious damage to the cover or jewel case. No missing or damaged pages or liner notes. The instructions (if applicable) are included in the box. May have very minimal identifying marks on the inside cover. Very minimal wear and tear.`,
  },
  {
    value: 'USED_GOOD',
    label: 'Good',
    conditionId: '5000',
    description: `An item in used but good condition. May have minor external damage including scuffs, scratches, or cracks but no holes or tears. For books, liner notes, or instructions, the majority of pages have minimal damage or markings and no missing pages.`,
  },
  {
    value: 'USED_ACCEPTABLE',
    label: 'USED_ACCEPTABLE',
    conditionId: '6000',
    description:
      'An item with obvious or significant wear, but still operational. For books, liner notes, or instructions, the item may have some damage to the cover but the integrity is still intact. Instructions and/or box may be missing. For books, possible writing in margins, etc., but no missing pages or anything that would compromise the legibility or understanding of the text.',
  },
  {
    value: 'FOR_PARTS_OR_NOT_WORKING',
    label: 'FOR_PARTS_OR_NOT_WORKING',
    conditionId: '7000',
    description: `An item that does not function as intended and is not fully operational. This includes items that are defective in ways that render them difficult to use, items that require service or repair, or items missing essential components. `,
  },
];

export const CategoryType = [
  {
    value: 'ALL_EXCLUDING_MOTORS_VEHICLES',
    label: 'All Listings Besides Motor Vehicle Listings',
  },
  {
    value: 'MOTORS_VEHICLES',
    label: 'Motor Vehicle Listings',
  },
];

export const ShippingCostType = [
  {
    value: 'CALCULATED',
    label:
      'With calculated shipping, the shipping cost varies according to the shipping location and the size and weight of the package.',
  },
  {
    value: 'FLAT_RATE',
    label:
      'With flat-rate shipping, the shipping cost will be the same for all buyers',
  },
  {
    value: 'NOT_SPECIFIED',
    label:
      'This value indicates that the shipping cost type has not been specified',
  },
];

export const booleanOptions = [
  {
    value: true,
    label: 'Enabled',
  },
  {
    value: false,
    label: 'Disabled',
  },
];

export const currency = [
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'CAD',
    label: 'CAD',
  },
  {
    value: 'AUD',
    label: 'AUD',
  },
  {
    value: 'AED',
    label: 'AED',
  },
];