import React, { useState, useEffect } from "react";
import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./Information.css";
import logoImg from "./ebaylogo.png";
import "./../styles.css"

const SelectStoreForm = () => {
  const [formData, setFormData] = useState({
    selectedStore: false,
  });
  const company_id = localStorage.getItem('company_id');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [stores, setStores] = useState([]);

  // if (company_id || (company_id && company_id != '0') ) {

  useEffect(() => {
    console.log('I came to get Store Details')
    async function fetchStoreInfo() {
      try {
    const StoreList = await axios.post("/api/v1/getStoreDetails", {
      company_id: company_id});
    console.log(StoreList.data);
    setStores(StoreList.data.stores);
  } catch (err) {
    console.log(err)
  }
} fetchStoreInfo();
  },[])
  

  const updateStore = async (e) => {

    const token = localStorage.getItem('token');
    console.log("start store integration", token);
    const response = await fetch('/ebayserverlogin', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token
        })
      });
      console.log("waiting for response");
      if(!response.ok)
        {
          if (response.status === 301) {
            // Token expired
            alert('Session expired Login Again');
            localStorage.removeItem('token')
            navigate('/');
          } else if (response.status === 302 || response.status === 303) {
            // Invalid token or other client-side error
            alert('Invalid Session, Login Again');
            localStorage.removeItem('token')
            navigate('/');
          } 
        }

        console.log(response);
        const data = await response.json();
        console.log(data);
        if (response) {
          console.log("Store updated successfully:", data.url);
          localStorage.setItem('token',data.token)
          window.location.href = `${data.url}`;
        } else {
          console.error("Failed to select store:", response);        
        } 
  };

  const addNewStore = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');
    console.log("start store integration", token);
    const response = await fetch('/ebayserverlogin', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token
        })
      });
      console.log("waiting for response");
      if(!response.ok)
        {
          if (response.status === 301) {
            // Token expired
            alert('Session expired Login Again');
            localStorage.removeItem('token')
            navigate('/');
          } else if (response.status === 302 || response.status === 303) {
            // Invalid token or other client-side error
            alert('Invalid Session, Login Again');
            localStorage.removeItem('token')
            navigate('/');
          } 
        }

        console.log(response);
        const data = await response.json();
        console.log(data);
        if (response) {
          console.log("Store updated successfully:", data.url);
          localStorage.setItem('token',data.token)
          window.location.href = `${data.url}`;
        } else {
          console.error("Failed to select store:", response);        
        }
  };

  const checkBoxStyle = {
    background: "#FFFFFF",
    borderRadius: "1.5rem",
    border: "none",
    outline: "none",
    cursor: "pointer",
    padding: "1rem",
    boxShadow: "0px 5px 5px 0px #00000040",
    marginTop: "2rem",
    boxSizing: "border-box",
  };


  const errorMessageStyle = {
    color: "red",
    margin: "5px 0",
  };

  return (
    <div id="tog-sidebar">
    <div className="main-container">
      <div className="select-store-form">
        <p htmlFor="selectedStore">
          Please select the store you want to connect to
        </p>


        {error && (
              <p className="error-message" style={errorMessageStyle}>
                {error}
              </p>
            )}

        { stores ? <div style={{ display: 'flex', flexDirection:"column"}}>
          <div style={{
          display:'flex',
          flexWrap:'wrap',
          gap:'5rem'}}>
             {stores.map ( store => (
          <button className="select-store-form-left" 
          id={store.name + '@' + store.id} 
          onClick={(e) => updateStore(e)}
          style={{
            backgroundImage:"url("+ (store.logo ? store.logo : logoImg) +")",
            backgroundPosition: 'top',
            backgroundRepeat: 'no-repeat'}}>
          
            {store.name} - 
            {store.store_name}

        </button>

        ))} </div>
        <form className="select-store-form-left" 
         onSubmit={(e) => addNewStore(e)} style={checkBoxStyle}>
          <input required type='text' name='store_name' placeholder="Enter Store Name"/>
          <button className="checkbox-inline" type='submit' 
          style={{
            // backgroundImage:"url("+ logoImg +")",
            // backgroundPosition: 'top',
            // backgroundRepeat: 'no-repeat',
            border:'none',
            backgroundColor: '#fff',
            marginBottom: '0'}}>
            <img src={logoImg} alt="store logo" />
          </button>          
          Add a New Store By Clicking here!
        </form>
       </div> : (
          <form className="select-store-form-left" 
          onSubmit={(e) => addNewStore(e)} style={checkBoxStyle}>
           <input required type='text' name='store_name' placeholder="Enter Store Name"/>
           <button className="checkbox-inline" type='submit' 
           style={{
            //  backgroundImage:"url("+ logoImg +")",
            //  backgroundPosition: 'top',
            //  backgroundRepeat: 'no-repeat',
             border:'none',
             backgroundColor: '#fff'}}>
             <img src={logoImg} alt="store logo" />
             
           </button>          
           Add a New Store By Clicking here!
         </form>

        )

        
}
      </div>
      <svg
        width="628"
        height="1356"
        viewBox="0 0 628 1356"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.05">
          <path
            d="M644.788 1349.2C989.849 1349.2 1269.58 1048.74 1269.58 678.099C1269.58 307.461 989.849 7 644.788 7C299.727 7 20 307.461 20 678.099C20 1048.74 299.727 1349.2 644.788 1349.2Z"
            stroke="#0C7489"
            stroke-opacity="0.7"
            stroke-width="40"
            stroke-miterlimit="10"
          />
          <path
            d="M644.789 1258.1C943.01 1258.1 1184.77 998.424 1184.77 678.098C1184.77 357.773 943.01 98.0977 644.789 98.0977C346.568 98.0977 104.812 357.773 104.812 678.098C104.812 998.424 346.568 1258.1 644.789 1258.1Z"
            stroke="#0C7489"
            stroke-opacity="0.7"
            stroke-width="40"
            stroke-miterlimit="10"
          />
          <path
            d="M644.788 1173.94C899.736 1173.94 1106.41 951.943 1106.41 678.099C1106.41 404.254 899.736 182.259 644.788 182.259C389.84 182.259 183.165 404.254 183.165 678.099C183.165 951.943 389.84 1173.94 644.788 1173.94Z"
            stroke="#0C7489"
            stroke-opacity="0.7"
            stroke-width="40"
            stroke-miterlimit="10"
          />
          <path
            d="M644.789 1090.22C856.692 1090.22 1028.47 905.709 1028.47 678.099C1028.47 450.488 856.692 265.974 644.789 265.974C432.885 265.974 261.103 450.488 261.103 678.099C261.103 905.709 432.885 1090.22 644.789 1090.22Z"
            stroke="#0C7489"
            stroke-opacity="0.7"
            stroke-width="40"
            stroke-miterlimit="10"
          />
          <path
            d="M644.789 1015.4C818.22 1015.4 958.813 864.385 958.813 678.099C958.813 491.813 818.22 340.798 644.789 340.798C471.358 340.798 330.765 491.813 330.765 678.099C330.765 864.385 471.358 1015.4 644.789 1015.4Z"
            stroke="#0C7489"
            stroke-opacity="0.7"
            stroke-width="40"
            stroke-miterlimit="10"
          />
          <path
            d="M644.789 935.801C777.292 935.801 884.708 820.424 884.708 678.099C884.708 535.774 777.292 420.396 644.789 420.396C512.285 420.396 404.87 535.774 404.87 678.099C404.87 820.424 512.285 935.801 644.789 935.801Z"
            stroke="#0C7489"
            stroke-opacity="0.7"
            stroke-width="40"
            stroke-miterlimit="10"
          />
        </g>
      </svg>
    </div>
    </div>
  );
};

export default SelectStoreForm;
