import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

const StoreIntegration = () => {
  const navigate = useNavigate();
  // Initial stores data
  const [stores, setStores] = useState([
    { id: 1, name: 'Store 1', logo: 'https://via.placeholder.com/50' },
    { id: 2, name: 'Store 2', logo: 'https://via.placeholder.com/50' },
  ]);

  // Handler to add a store
  const addStore = async () => {
    const token = localStorage.getItem('token');
    console.log("start store integration", token);
    const response = await fetch('/ebayserverlogin', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token
        })
      });
      console.log("waiting for response");
      if(!response.ok)
        {
          if (response.status === 301) {
            // Token expired
            alert('Session expired Login Again');
            localStorage.removeItem('token')
            navigate('/');
          } else if (response.status === 302 || response.status === 303) {
            // Invalid token or other client-side error
            alert('Invalid Session, Login Again');
            localStorage.removeItem('token')
            navigate('/');
          } 
        }

        console.log(response);
        const data = await response.json();
        console.log(data);
        if (response) {
          console.log("Store updated successfully:", data.url);
          localStorage.setItem('token',data.token)
          window.location.href = `${data.url}`;
        } else {
          console.error("Failed to select store:", response);        
        }
  };

  // Handler to remove a store
  const removeStore = (id) => {
    const updatedStores = stores.filter(store => store.id !== id);
    setStores(updatedStores);
  };

  // Handler to show store details
  const showDetails = (store) => {
    alert(`Store Name: ${store.name}`);
  };

  return (
    <div>
      <h1>Store Integration</h1>
      <button onClick={addStore}>Add Store</button>
      <div>
        {stores.map(store => (
          <div key={store.id} style={{ border: '1px solid black', padding: '10px', margin: '10px 0' }}>
            <img src={store.logo} alt={store.name} style={{ width: '50px', height: '50px' }} />
            <h2>{store.name}</h2>
            <button onClick={() => showDetails(store)}>Show Details</button>
            <button onClick={() => removeStore(store.id)}>Remove Store</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StoreIntegration;
