import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate,useParams  } from "react-router-dom";
import "./Information.css";
import "./../styles.css";
import axios from "axios";

const ViewReturnPolicyForm = () => {
    const { policyId } = useParams();
  const marketplaceOptions = {
    EBAY_US: "United States",
    EBAY_AT: "Austria",
    EBAY_AU: "Australia",
    EBAY_BE: "Belgium",
    EBAY_CA: "Canada",
    EBAY_CH: "Switzerland",
    // 'EBAY_CN': 'Reserved for future use',
    // 'EBAY_CZ': 'Reserved for future use',
    EBAY_DE: "Germany",
    // 'EBAY_DK': 'Reserved for future use',
    EBAY_ES: "Spain",
    // 'EBAY_FI': 'Reserved for future use',
    EBAY_FR: "France",
    EBAY_GB: "Great Britain",
    // 'EBAY_GR': 'Reserved for future use',
    EBAY_HK: "Hong Kong",
    // 'EBAY_HU': 'Reserved for future use',
    // 'EBAY_ID': 'Reserved for future use',
    EBAY_IE: "Ireland",
    // 'EBAY_IL': 'Reserved for future use',
    EBAY_IN: "India",
    EBAY_IT: "Italy",
    // 'EBAY_JP': 'Reserved for future use',
    EBAY_MY: "Malaysia",
    EBAY_NL: "Netherlands",
    // 'EBAY_NO': 'Reserved for future use',
    // 'EBAY_NZ': 'Reserved for future use',
    // 'EBAY_PE': 'Reserved for future use',
    EBAY_PH: "Philippines",
    EBAY_PL: "Poland",
    // 'EBAY_PR': 'Reserved for future use',
    // 'EBAY_PT': 'Reserved for future use',
    // 'EBAY_RU': 'Reserved for future use',
    // 'EBAY_SE': 'Reserved for future use',
    EBAY_SG: "Singapore",
    EBAY_TH: "Thailand",
    EBAY_TW: "Taiwan",
   
    EBAY_VN: "Vietnam",
    // 'EBAY_ZA': 'Reserved for future use',
    // 'EBAY_HALF_US': 'No longer used',
    EBAY_MOTORS_US: "Auto Parts and Vehicles on the EBAY_US marketplace",
  };

  const handleMarketplaceChange = (marketplaceId) => {
    setFormData({ ...formData, marketplaceId: marketplaceId })
  };

  const store_id = localStorage.getItem('store_id');
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    // Fetch existing policy details using policyId
    const fetchPolicyDetails = async () => {
      try {
        const response = await axios.post(`/api/v1/viewReturnPolicy/${policyId}`, {
          store_id: store_id
        });
        console.log(response.data);
        setFormData(response.data);
        
      } catch (error) {
        console.error('Error fetching policy details:', error);
        alert("Internal Ebay Server Error")
        window.location.reload();
      }
    };

    fetchPolicyDetails();
  }, [policyId]);


  const [errors, setErrors] = useState({
   
    nameError: "",
    marketplaceIdError: "",
    ReturnShippingCostPayer: "",
    ReturnPeriodValue: "",
    ReturnPeriodUnit:"",
  });
  const [expandedMarketplaceId, setExpandedMarketplaceId] = useState(false);
  const [expandedRefundMethod, setExpandedRefundMethod] = useState(false);
  const [expandedReturnMethod, setExpandedReturnMethod] = useState(false);
  
  const [expandedReturnPeriodUnit, setExpandedReturnPeriodUnit] =
    useState(false);
  const [expandedReturnShippingCostPayer, setExpandedReturnShippingCostPayer] =
    useState(false);
  const navigate = useNavigate();

  const toggleSelectMarketplaceId = () => {
    setExpandedMarketplaceId((prevExpanded) => !prevExpanded);
  };

  const toggleSelectRefundMethod = () => {
    setExpandedRefundMethod(
      (prevExpandedRefundMethod) => !prevExpandedRefundMethod
    );
  };

  const toggleSelectReturnMethod = () => {
    setExpandedReturnMethod(
      (prevExpandedReturnMethod) => !prevExpandedReturnMethod
    );
  };

  const toggleSelectReturnShippingCostPayer = () => {
    setExpandedReturnShippingCostPayer((prevExpanded) => !prevExpanded);
  };

  const toggleSelectReturnPeriodUnit = () => {
    setExpandedReturnPeriodUnit(
      (prevExpandedReturnPeriodUnit) => !prevExpandedReturnPeriodUnit
    );
  };


  
   // Function to handle input change
   const handleNameChange = (e) => {
    const newName = e.target.value;

    // Check if the length exceeds 64 characters
    if (newName.length > 64) {
      setErrors({ ...errors, nameError: 'Return Policy Name cannot exceed 64 characters' });
    } else {
      setErrors({ ...errors, nameError: '' }); // Clear the error if within limit
      setFormData({ ...formData, name: newName }); // Update the form data
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
     // Validation
    let hasError = false;
    const newErrors = {
    
      nameError: "",
      marketplaceIdError: "",
      ReturnShippingCostPayer: "",
      ReturnPeriodValue: "",
      ReturnPeriodUnit:"",
    };
    
    if(formData.returnsAccepted){
      
      if (!formData.returnPeriod.unit) {
        newErrors.ReturnPeriodUnit = "Return Period Unit is required!";
        hasError = true;
      }
      if (!formData.returnPeriod.value) {
        newErrors.ReturnPeriodValue = "Return Period value is required!";
        hasError = true;
      }
      if (!formData.returnShippingCostPayer) {
        newErrors.ReturnShippingCostPayer = "Return Shipping Cost Payer is required!";
        hasError = true;
      }
    }
   
   

    

    if (!formData.name) {
      newErrors.nameError = "Return Policy Name is required!";
      hasError = true;
    }

    if (!formData.marketplaceId) {
      newErrors.marketplaceIdError = "Marketplace ID is required!";
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
      return; // Stop submission if there are errors
    }
   
    try {
      console.log(formData);
      const newData = {
        ...formData,
        returnPeriod: {
          ...formData.returnPeriod,
          value: parseInt(formData.returnPeriod.value) // Convert string to number
        }
      };
      if(!formData.returnsAccepted){
        delete newData.returnPeriod;
        delete newData.returnShippingCostPayer;
      }

      const response = await axios.post(`/api/v1/updateReturnPolicy/${policyId}`, {
        data: newData,
        store_id: store_id
      });
      console.log("Return policy created successfully:", response.data);
      alert("Return policy Updated successfully!");
      navigate("/Admin/policieslocations/ReturnPolicy");
    } catch (err) {
      console.error("Error:", err);
      //setError("Error creating return policy");
      alert("Internal Ebay Server Issue"); // Show error message
      window.location.reload();
    }
  };
  

  const inputStyle = {
    width: "100%",
    height: "60px",
    background: "#FFFFFF",
    border: "none",
    boxShadow: "0px 5px 5px 0px #00000040",
    borderRadius: "10px",
    marginBottom: "3rem",
    padding: "1rem",
    boxSizing: "border-box",
  };

  const buttonStyle = {
    cursor: "pointer",
    height: "60px",
    background: "#142B41",
    boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    color: "#FFFFFF",
    marginTop: "2rem",
  };

  const checkBoxStyle = {
    background: "#FFFFFF",
    borderRadius: "10px",
    border: "none",
    outline: "none",
    cursor: "pointer",
  };

  const errorMessageStyle = {
    color: "red",
    margin: "5px 0",
  };

  return (
    <div id="tog-sidebar">
    <div className="return-policy-form">
    {formData ? ( 
      <div className="return-policy-form-left">
        <h1>Return Policy</h1>
        <form onSubmit={handleSubmit}>       
          <label htmlFor="returnPolicyName">*Return Policy Name</label>
          {errors.nameError && (
              <p className="error-message" style={errorMessageStyle}>{errors.nameError}  </p>
            )}
          <input
            type="text"
            id="returnPolicyName"
            name="name"
            placeholder="Please enter the return policy name."
            value={formData.name}
            onChange={handleNameChange}
            style={inputStyle}
          />
            <label htmlFor="Description">Description</label>
         
          <input
            type="text"
            id="Description"
            name="description"
            placeholder="Please enter the return policy name."
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            style={inputStyle}
            />

          {/* Marketplace ID */}
          <div
            className="custom-select-container"
            style={{ height: expandedMarketplaceId ? "300px" : "" }}
          >
            <label htmlFor="marketplaceId">*Marketplace ID</label>
            {errors.marketplaceIdError && (
              <p className="error-message" style={errorMessageStyle}>{errors.marketplaceIdError}  </p>
            )}
            <div
              className={`custom-select ${
                expandedMarketplaceId ? "active" : ""
              }`}
            >
              <button
                type="button"
                className="select-button"
                onClick={toggleSelectMarketplaceId}
                aria-expanded={expandedMarketplaceId}
              >
                  <span className="selected-value">
                    {formData.marketplaceId === "" ? "Select" : formData.marketplaceId}
                  </span>
                <span className="arrow"></span>
              </button>
              <ul className="select-dropdown">
                {Object.entries(marketplaceOptions).map(([id, name]) => (
                  <li key={id}>
                    <label htmlFor={`marketplaceId${id}`}>
                      <input
                        type="radio"
                        id={`marketplaceId${id}`}
                        name="marketplaceId"
                        value={id}
                        checked={formData.marketplaceId === id}
                        onChange={() => handleMarketplaceChange(id)}
                      />
                      {id} // {name}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div
            className="custom-select-container"
            style={{ height: expandedReturnMethod ? "300px" : "" }}
          >
            <label htmlFor="returnMethod">Return Method</label>
            <div
              className={`custom-select ${
                expandedReturnMethod ? "active" : ""
              }`}
            >
              <button
                type="button"
                className="select-button"
                onClick={toggleSelectReturnMethod}
                aria-expanded={expandedReturnMethod}
              >
                <span
                  className={`selected-value ${
                    formData.returnMethod !== "" &&
                    formData.returnMethod !== "Select"
                      ? "selected-black"
                      : ""
                  }`}
                >
                  {formData.returnMethod ? formData.returnMethod : "Select"}
                </span>
                <span className="arrow"></span>
              </button>
              <ul className="select-dropdown">
                <li>
                  <label htmlFor="exchange">
                    <input
                      type="radio"
                      id="exchange"
                      name="returnMethod"
                      value="EXCHANGE"
                      checked={formData.returnMethod === "EXCHANGE"}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          returnMethod: e.target.value,
                        })
                      }
                    />
                    EXCHANGE
                  </label>
                </li>
                <li>
                  <label htmlFor="storeCredit">
                    <input
                      type="radio"
                      id="storeCredit"
                      name="returnMethod"
                      value="REPLACEMENT"
                      checked={formData.returnMethod === "REPLACEMENT"}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          returnMethod: e.target.value,
                        })
                      }
                    />
                    REPLACEMENT
                  </label>
                </li>
              </ul>
            </div>
          </div>

          <div
            className="custom-select-container"
            style={{ height: expandedRefundMethod ? "250px" : "" }}
          >
            <label htmlFor="refundMethod">Refund Method</label>
            <div
              className={`custom-select ${
                expandedRefundMethod ? "active" : ""
              }`}
            >
              <button
                type="button"
                className="select-button"
                onClick={toggleSelectRefundMethod}
                aria-expanded={expandedRefundMethod}
              >
                <span
                  className={`selected-value ${
                    formData.refundMethod !== "" &&
                    formData.refundMethod !== "Select"
                      ? "selected-black"
                      : ""
                  }`}
                >
                  {formData.refundMethod ? formData.refundMethod : "Select"}
                </span>
                <span className="arrow"></span>
              </button>
              <ul className="select-dropdown">
                <li>
                  <label htmlFor="money">
                    <input
                      type="radio"
                      id="money"
                      name="refundMethod"
                      value="MONEY_BACK"
                      checked={formData.refundMethod === "MONEY_BACK"}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          refundMethod: e.target.value,
                        })
                      }
                    />
                    MONEY_BACK
                  </label>
                </li>
               
              </ul>
            </div>
          </div>

          {/* Returns Accepted */}
          <label htmlFor={`returnsAccepted`}>Returns Accepted</label>
          <div className="checkbox-inline">
            <label htmlFor={`returnsAcceptedTrue`}>
              Check if returns are accepted.
            </label>
            <input
              type="checkbox"
              id={`returnsAcceptedTrue`}
              name={`returnsAccepted`}
              value="true"
              checked={formData.returnsAccepted}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  returnsAccepted: e.target.checked,
                })
              }
              style={checkBoxStyle}
            />
          </div>
         
           {/* Return Shipping Cost Payer */}
            {formData.returnsAccepted && ( // Show the Return Shipping Cost Payer field only if returnsAccepted is true
              <div className="custom-select-container"
              style={{ height: expandedReturnShippingCostPayer ? "300px" : "" }}
              >
                <label htmlFor="returnShippingCostPayer">
                  *Return Shipping Cost Payer
                </label>
                {errors.ReturnShippingCostPayer && (
              <p className="error-message" style={errorMessageStyle}>{errors.ReturnShippingCostPayer}  </p>
            )}
                <div className={`custom-select ${expandedReturnShippingCostPayer ? "active" : ""}`}>
                  <button
                    type="button"
                    className="select-button"
                    onClick={toggleSelectReturnShippingCostPayer}
                    aria-expanded={expandedReturnShippingCostPayer}
                  >
                    <span
                      className={`selected-value ${
                        formData.returnShippingCostPayer !== "" &&
                        formData.returnShippingCostPayer !== "Select"
                          ? "selected-black"
                          : ""
                      }`}
                    >
                      {formData.returnShippingCostPayer
                        ? formData.returnShippingCostPayer
                        : "Select"}
                    </span>
                    <span className="arrow"></span>
                  </button>
                  <ul className="select-dropdown">
                    <li>
                      <label htmlFor="seller">
                        <input
                          type="radio"
                          id="seller"
                          name="returnShippingCostPayer"
                          value="SELLER"
                          checked={formData.returnShippingCostPayer === "SELLER"}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              returnShippingCostPayer: e.target.value,
                            })
                          }
                        />
                        SELLER
                      </label>
                    </li>
                    <li>
                      <label htmlFor="buyer">
                        <input
                          type="radio"
                          id="buyer"
                          name="returnShippingCostPayer"
                          value="BUYER"
                          checked={formData.returnShippingCostPayer === "BUYER"}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              returnShippingCostPayer: e.target.value,
                            }) 
                            
                          }}
                        />
                        
                        BUYER
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            )}
    {formData.returnsAccepted && (
       <div>
          <label htmlFor="returnPeriodValue">*Return Period Value</label>
          {errors.ReturnPeriodValue && (
              <p className="error-message" style={errorMessageStyle}>{errors.ReturnPeriodValue}  </p>
            )}
          <input
            type="number"
            id="returnPeriodValue"
            name="returnPeriodValue"
            placeholder="Please enter the return period value. "
            value={formData.returnPeriod.value}
            onChange={(e) => {
              setFormData({ ...formData, returnPeriod: { ...formData.returnPeriod, value: e.target.value } });
            }}
            style={inputStyle}
          />

          <div
            className="custom-select-container"
            style={{ height: expandedReturnPeriodUnit ? "380px" : "" }}
          >
            <label htmlFor="returnPeriodUnit">*Return Period Unit</label>
            {errors.ReturnPeriodUnit && (
              <p className="error-message" style={errorMessageStyle}>{errors.ReturnPeriodUnit}  </p>
            )}
            <div
              className={`custom-select ${
                expandedReturnPeriodUnit ? "active" : ""
              }`}
            >
              <button
                type="button"
                className="select-button"
                onClick={toggleSelectReturnPeriodUnit}
                aria-expanded={expandedReturnPeriodUnit}
              >
                <span
                  className={`selected-value ${
                    formData.returnPeriod.unit !== "" &&
                    formData.returnPeriod.unit !== "Select"
                      ? "selected-black"
                      : ""
                  }`}
                >
                  {formData.returnPeriod.unit
                    ? formData.returnPeriod.unit
                    : "Select"}
                </span>
                <span className="arrow"></span>
              </button>
              <ul className="select-dropdown">
              <li>
                  <label htmlFor="hour">
                    <input
                      type="radio"
                      id="hour"
                      name="returnPeriodUnit"
                      value="HOUR"
                      checked={formData.returnPeriod.unit === "HOUR"}
                      onChange={(e) => {
                       
                        setFormData({ ...formData, returnPeriod: { ...formData.returnPeriod, unit: e.target.value } });
                      }}
                    />
                    HOUR
                  </label>
                </li>

                <li>
                  <label htmlFor="day">
                    <input
                      type="radio"
                      id="day"
                      name="returnPeriodUnit"
                      value="DAY"
                      checked={formData.returnPeriod.unit === "DAY"}
                      onChange={(e) => {
                       
                        setFormData({ ...formData, returnPeriod: { ...formData.returnPeriod, unit: e.target.value } });
                      }}
                    />
                    DAY
                  </label>
                </li>
                <li>
                  <label htmlFor="week">
                    <input
                      type="radio"
                      id="week"
                      name="returnPeriodUnit"
                      value="WEEK"
                      checked={formData.returnPeriod.unit === "WEEK"}
                      onChange={(e) => {
                       
                        setFormData({ ...formData, returnPeriod: { ...formData.returnPeriod, unit: e.target.value } });
                      }}
                    />
                    WEEK
                  </label>
                </li>
                <li>
                  <label htmlFor="month">
                    <input
                      type="radio"
                      id="month"
                      name="returnPeriodUnit"
                      value="MONTH"
                      checked={formData.returnPeriod.unit === "MONTH"}
                      onChange={(e) => {
                       
                        setFormData({ ...formData, returnPeriod: { ...formData.returnPeriod, unit: e.target.value } });
                      }}
                    />
                    MONTH
                  </label>
                </li>
              </ul>
            </div>
          </div>
         
        </div>
         )}
          <button className="next-btn" type="submit" style={buttonStyle}>
            Save
          </button>

         
        </form>
      </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
    </div>
  );
};

export default ViewReturnPolicyForm;
