import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./Information.css";
import "./../styles.css";
import axios from 'axios';

const PaymentPolicyForm = () => {
  const store_id= localStorage.getItem('store_id');
  const paymentMethodOptions = {
    1:"CASH_IN_PERSON",
    2:"CASH_ON_DELIVERY",
    3:"CASH_ON_PICKUP",
    4:"CASHIER_CHECK",
    5:"ESCROW",
    6:"MONEY_ORDER",
    7:"PERSONAL_CHECK",
    8:"OTHER",

  }
  const marketplaceOptions = {
    EBAY_US: "United States",
    EBAY_AT: "Austria",
    EBAY_AU: "Australia",
    EBAY_BE: "Belgium",
    EBAY_CA: "Canada",
    EBAY_CH: "Switzerland",
    // 'EBAY_CN': 'Reserved for future use',
    // 'EBAY_CZ': 'Reserved for future use',
    EBAY_DE: "Germany",
    // 'EBAY_DK': 'Reserved for future use',
    EBAY_ES: "Spain",
    // 'EBAY_FI': 'Reserved for future use',
    EBAY_FR: "France",
    EBAY_GB: "Great Britain",
    // 'EBAY_GR': 'Reserved for future use',
    EBAY_HK: "Hong Kong",
    // 'EBAY_HU': 'Reserved for future use',
    // 'EBAY_ID': 'Reserved for future use',
    EBAY_IE: "Ireland",
    // 'EBAY_IL': 'Reserved for future use',
    EBAY_IN: "India",
    EBAY_IT: "Italy",
    // 'EBAY_JP': 'Reserved for future use',
    EBAY_MY: "Malaysia",
    EBAY_NL: "Netherlands",
    // 'EBAY_NO': 'Reserved for future use',
    // 'EBAY_NZ': 'Reserved for future use',
    // 'EBAY_PE': 'Reserved for future use',
    EBAY_PH: "Philippines",
    EBAY_PL: "Poland",
    // 'EBAY_PR': 'Reserved for future use',
    // 'EBAY_PT': 'Reserved for future use',
    // 'EBAY_RU': 'Reserved for future use',
    // 'EBAY_SE': 'Reserved for future use',
    EBAY_SG: "Singapore",
    EBAY_TH: "Thailand",
    EBAY_TW: "Taiwan",
   
    EBAY_VN: "Vietnam",
    // 'EBAY_ZA': 'Reserved for future use',
    // 'EBAY_HALF_US': 'No longer used',
    EBAY_MOTORS_US: "Auto Parts and Vehicles on the EBAY_US marketplace",
  };
  const handleMarketplaceChange = (marketplaceId) => {
    setFormData({ ...formData, marketplaceId: marketplaceId })
  };
  const handlepaymentMethodsChange = (paymentMethods) => {
    const paymentMethod = [...formData.paymentMethods];
    paymentMethod[0].paymentMethodType = paymentMethods;
    setFormData({
      ...formData,
      paymentMethods: paymentMethod,
    });
   
  };
 
  const [formData, setFormData] = useState({
    name: "",
    marketplaceId: "",
    categoryTypes: [
      {
        name: ""
      }
    ],
    paymentMethods: [
      {
        paymentMethodType: ""
      }
    ]
  });

  const [error, setError] = useState(null);
  const [expandedMarketplaceId, setExpandedMarketplaceId] = useState(false);
  //expandedPaymentMethod
  const [expandedPaymentMethod, setexpandedPaymentMethod] = useState(false);
  const [expandedCategoryType, setExpandedCategoryType] = useState(false);
  const navigate = useNavigate();

  const toggleSelectMarketplaceId = () => {
    setExpandedMarketplaceId((prevExpanded) => !prevExpanded);
  };

  const toggleSelectCategoryType = () => {
    setExpandedCategoryType((prevExpanded) => !prevExpanded);
  };

  const toggleSelectPaymentMethod = () => {
    setexpandedPaymentMethod((prevExpanded) => !prevExpanded);
  };
  
  const [errors, setErrors] = useState({
      nameError: "",
      marketplaceIdError: "",
      categoryTypesError: "",
  });
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validation
    let hasError = false;
    const newErrors = {
      nameError: "",
      marketplaceIdError: "",
      categoryTypesError: "",
    };
    
   
   
  
    if (!formData.name) {
      newErrors.nameError = "Payment Policy Name is required!";
      hasError = true;
    }

    if (!formData.marketplaceId) {
      newErrors.marketplaceIdError = "Marketplace ID is required!";
      hasError = true;
    }
    if (!formData.categoryTypes[0].name) {
      newErrors.categoryTypesError = "Category Type is required!";
      hasError = true;
    }
    if (hasError) {
      setErrors(newErrors);
      return; // Stop submission if there are errors
    }
   
    try {
      console.log(formData);
      const response = await axios.post("/api/v1/createPaymentPolicy", {
        data: formData,
        store_id: store_id
      });
      console.log("Payment policy created successfully:", response.data); 
      alert('"Payment policy created successfully!');
      navigate("/Admin/policieslocations/PaymentPolicy");
    } catch (err) {
      console.error("Error:", err);
      alert('There was a problem with an eBay internal system or process');
      window.location.reload();
    }
    
    
  };
  
   // Function to handle input change
   const handleNameChange = (e) => {
    const newName = e.target.value;

    // Check if the length exceeds 64 characters
    if (newName.length > 64) {
      setErrors({ ...errors, nameError: 'Payment Policy Name cannot exceed 64 characters' });
    } else {
      setErrors({ ...errors, nameError: '' }); // Clear the error if within limit
      setFormData({ ...formData, name: newName }); // Update the form data
    }
  };
  
    // Function to handle input change
    const handleDescriptionChange = (e) => {
      const newName = e.target.value;
  
      // Check if the length exceeds 250 characters
      if (newName.length > 250) {
        setErrors({ ...errors, descriptionError: 'Payment Policy Description cannot exceed 250 characters' });
      } else {
        setErrors({ ...errors, descriptionError: '' }); // Clear the error if within limit
        setFormData({ ...formData, description: newName }); // Update the form data
      }
    };

  const checkBoxStyle = {
    background: "#FFFFFF",
    borderRadius: "10px",
    border: "none",
    outline: "none",
    cursor: "pointer",
  };
  
  const inputStyle = {
    width: "100%",
    height: "60px",
    background: "#FFFFFF",
    border: "none",
    boxShadow: "0px 5px 5px 0px #00000040",
    borderRadius: "10px",
    marginBottom: "3rem",
    padding: "1rem",
    boxSizing: "border-box",
  };

  const buttonStyle = {
    cursor: "pointer",
    height: "60px",
    background: "#142B41",
    boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    color: "#FFFFFF",
    marginTop: "2rem",
  };

  const errorMessageStyle = {
    color: "red",
    margin: "5px 0",
  };
  

  return (
    <div id="tog-sidebar">
    <div className="payment-policy-form">
      <div className="payment-policy-form-left">
        <h1>Payment Policy</h1>
        <form onSubmit={handleSubmit}>
          {/* Payment Policy Name */}
          <label htmlFor="paymentPolicyName">*Payment Policy Name</label>
          {errors.nameError && (
              <p className="error-message" style={errorMessageStyle}>{errors.nameError}  </p>
            )}
          <input
            type="text"
            id="paymentPolicyName"
            name="paymentPolicyName"
            value={formData.name}
            placeholder="Please enter the payment policy name."
            onChange={handleNameChange}
            style={inputStyle}
          />

          <label htmlFor="Description">Description</label>
          {errors.descriptionError && (
              <p className="error-message" style={errorMessageStyle}>{errors.descriptionError}  </p>
            )}
         <input
           type="text"
           id="Description"
           name="description"
           placeholder="Please enter the Payment Policy Description."
           value={formData.description}
           onChange={handleDescriptionChange}
           style={inputStyle}
           />

          {/* Marketplace ID */}
          <div
            className="custom-select-container"
            style={{ height: expandedMarketplaceId ? "300px" : "" }}
          >
            <label htmlFor="marketplaceId">*Marketplace ID</label>
            {errors.marketplaceIdError && (
              <p className="error-message" style={errorMessageStyle}>{errors.marketplaceIdError}  </p>
            )}
            <div
              className={`custom-select ${
                expandedMarketplaceId ? "active" : ""
              }`}
            >
              <button
                type="button"
                className="select-button"
                onClick={toggleSelectMarketplaceId}
                aria-expanded={expandedMarketplaceId}
              >
                  <span className="selected-value">
                    {formData.marketplaceId === "" ? "Select" : formData.marketplaceId}
                  </span>
                <span className="arrow"></span>
              </button>
              <ul className="select-dropdown">
                {Object.entries(marketplaceOptions).map(([id, name]) => (
                  <li key={id}>
                    <label htmlFor={`marketplaceId${id}`}>
                      <input
                        type="radio"
                        id={`marketplaceId${id}`}
                        name="marketplaceId"
                        value={id}
                        checked={formData.marketplaceId === id}
                        onChange={() => handleMarketplaceChange(id)}
                      />
                      {id} // {name}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
           <div
              className="custom-select-container"
              style={{ height: expandedCategoryType ? "300px" : "" }}
            >
              <label htmlFor="categoryType">*Category Type</label>
              {errors.categoryTypesError && (
              <p className="error-message" style={errorMessageStyle}>{errors.categoryTypesError}  </p>
            )}
              <div
                className={`custom-select ${expandedCategoryType ? "active" : ""}`}
              >
                <button
                  type="button"
                  className="select-button"
                  onClick={toggleSelectCategoryType}
                  aria-expanded={expandedCategoryType}
                >
                  <span
                    className={`selected-value ${
                      formData.categoryTypes[0].name !== "" ? "selected-black" : ""
                    }`}
                  >
                    {formData.categoryTypes[0].name !== "" ? formData.categoryTypes[0].name : "Select"}
                  </span>
                  <span className="arrow"></span>
                </button>
                <ul className="select-dropdown">
                <li>
                  <label htmlFor="all_excluding_motors_vehicles">
                    <input
                      type="radio"
                      id="all_excluding_motors_vehicles"
                      name="categorytypename"
                      value="ALL_EXCLUDING_MOTORS_VEHICLES"
                      checked={formData.categoryTypes[0].name === "ALL_EXCLUDING_MOTORS_VEHICLES"}
                      onChange={(e) => {
                        const category = [...formData.categoryTypes];
                        category[0].name = e.target.value;
                        setFormData({
                          ...formData,
                          categoryTypes: category,
                        });
                      }}
                    />
                    ALL_EXCLUDING_MOTORS_VEHICLES
                  </label>
                </li>
                <li>
                  <label htmlFor="motors_vehicles">
                    <input
                      type="radio"
                      id="motors_vehicles"
                      name="categorytypename"
                      value="MOTORS_VEHICLES"
                      checked={formData.categoryTypes[0].name === "MOTORS_VEHICLES"}
                      onChange={(e) => {
                        const category = [...formData.categoryTypes];
                        category[0].name = e.target.value;
                        setFormData({
                          ...formData,
                          categoryTypes: category,
                        });
                      }}
                    />
                    MOTORS_VEHICLES
                  </label>
                </li>
                 
                </ul>
              </div>
            </div>
          
            <label htmlFor={`immediatePay`}>Immediate Pay</label>
          <div className="checkbox-inline">
            <label htmlFor={`immediatePayTrue`}>
              Check if immediate pay is accepted.
            </label>
            <input
              type="checkbox"
              id={`immediatePayTrue`}
              name={`immediatePay`}
              value="true"
              checked={formData.immediatePay}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  immediatePay: e.target.checked,
                })
              }
              style={checkBoxStyle}
            />
          </div>


          {/* Payment Method Type */}

          <div
            className="custom-select-container"
            style={{ height: expandedPaymentMethod ? "300px" : "" }}
          >
            <label htmlFor="paymentMethodType">Payment Method Type</label>
            <div
              className={`custom-select ${
                expandedPaymentMethod ? "active" : ""
              }`}
            >
              <button
                type="button"
                className="select-button"
                onClick={toggleSelectPaymentMethod}
                aria-expanded={expandedPaymentMethod}
              >
                  <span className="selected-value">
                    {formData.paymentMethods[0].paymentMethodType === "" ? "Select" : formData.paymentMethods[0].paymentMethodType}
                  </span>
                <span className="arrow"></span>
              </button>
              <ul className="select-dropdown">
                {Object.entries(paymentMethodOptions).map(([id,name]) => (
                  <li key={name}>
                    <label htmlFor={`PaymentMethod${name}`}>
                      <input
                        type="radio"
                        id={`PaymentMethod${name}`}
                        name="PaymentMethod"
                        value={name}
                        checked={formData.paymentMethods[0].paymentMethodType  === name}
                        onChange={() => handlepaymentMethodsChange(name)}
                      />
                      {name} 
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>

         

          <button className="next-btn" type="submit" style={buttonStyle}>
            Save
          </button>
          {error && (
            <p className="error-message" style={errorMessageStyle}>
              {error}
            </p>
          )}
        </form>
      </div>
    </div>
    </div>
  );
};

export default PaymentPolicyForm;
