import React, { useState, useEffect } from 'react';
import { useNavigate  , Link, useLocation } from 'react-router-dom';
import './InventoryTable.css';
import "./../styles.css"
import * as XLSX from 'xlsx';

const InventoryTable = () => {
  const [inventoryItems, setInventoryItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [viewItemSku, setViewItemSku] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [displayedItems, setDisplayedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const navigate = useNavigate();

  
  useEffect(() => {
    // Fetch inventory items from the server when the component mounts
    fetchInventoryItems();
  }, []);

  const fetchInventoryItems = async () => {
    try {

      const token = localStorage.getItem('token');

      // Fetch data from your server API endpoint (replace with your actual API endpoint)
      const response = await fetch('/api/v1/tableInventoryItems', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token
        })
      });
      
      console.log(response)
      if(!response.ok)
        {
          if (response.status === 301) {
            // Token expired
            alert('Session expired,Login Again');
            localStorage.removeItem('token')
            navigate('/');
          } else if (response.status === 302 || response.status === 303) {
            // Invalid token or other client-side error
            alert('Invalid Session, Login Again');
            localStorage.removeItem('token')
            navigate('/');
          } 
        }

      const data = await response.json();
      localStorage.setItem('token', data.token)

      setInventoryItems(data.data);
      setFilteredItems(data.data);
    } catch (error) {
      alert("Error fetching inventory items:", error);
    }
  };

  const editItem = (sku) => {
    // Implement your logic for editing an item
    console.log("Edit item with SKU:", sku);
    setItemToEdit(sku);
  };

  const viewItem = (sku) => {
    // Implement your logic for viewing an item
    console.log("View item with SKU:", sku);
    setViewItemSku(sku);
  };

  const deleteItem = async (sku) => {
    try {
      const token = localStorage.getItem('token');
      // Make a DELETE request to your API endpoint to delete the item
      const response = await fetch(`/api/v1/InventoryItems/${sku}`, {
        method: "POST",
        headers: {
          "Content-Type":"application/json",
        },
        body: JSON.stringify({
          token: token
        })
        
      });
      console.log(response)
      if(!response.ok)
        {
          if (response.status === 301) {
            // Token expired
            alert('Session expired,Login Again');
            localStorage.removeItem('token')
            navigate('/');
          } else if (response.status === 302 || response.status === 303) {
            // Invalid token or other client-side error
            alert('Invalid Session, Login Again');
            localStorage.removeItem('token')
            navigate('/');
          }
        }

      alert("Product Deleted ");
      localStorage.setItem(token,response.json().token);

      // Once deleted successfully, fetch the updated list of items
      fetchInventoryItems();
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting item:", error);
    }

    // fetchInventoryItems();
    // // Reset the itemToDelete and hide the modal
    // setItemToDelete(null);
    // setShowDeleteModal(false);
  };

  const showDeleteConfirmation = (sku) => {
    // Set the item to be deleted and show the delete confirmation modal
    console.log("modal show");
    setItemToDelete(sku);
    setShowDeleteModal(true);
  };

  const hideDeleteConfirmation = () => {
    // Reset the itemToDelete and hide the delete confirmation modal
    setItemToDelete(null);
    setShowDeleteModal(false);
  };

  useEffect(() => {
    if (viewItemSku) {
      navigate(`/products/${viewItemSku}`); // Use navigate to redirect
    }
  }, [viewItemSku, navigate]);

  useEffect(() => {
    if (itemToEdit) {
      navigate(`/editproducts/${itemToEdit}`);
    }
  });

  useEffect(() => {
    setFilteredItems(inventoryItems);
  }, [inventoryItems]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query.trim() === "") {
      setFilteredItems(inventoryItems);
    } else {
      const filtered = inventoryItems.filter(
        (item) =>
          item.title.toLowerCase().includes(query.toLowerCase()) ||
          item.sku.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredItems(filtered);
    }
  };

  const clearSearch = () => {
    setSearchQuery("");
    setFilteredItems(inventoryItems);
  };

  // function convertJsonToCsv(data) {
  //   if (!Array.isArray(data) || data.length === 0) {
  //     return ''; // Return empty string if data is not an array or is empty
  //   }
  
  //   // Extract headers from the keys of the first object in the data array
  //   const headers = Object.keys(data[0]);
  
  //   // Generate CSV header row
  //   const csvHeader = headers.join(',') + '\n';
  
  //   // Generate CSV data rows
  //   const csvRows = data.map(obj => {
  //     return headers.map(header => obj[header]).join(',');
  //   }).join('\n');
  
  //   // Combine header row and data rows to form the complete CSV string
  //   const csv = csvHeader + csvRows;
  
  //   return csv;
  // }
  const flattenData = (data) => {
    return data.map((item) => {
      const { title, sku, product } = item;
      const flattenedAspects = {};
  
      // Flattening aspects
      Object.keys(product.aspects).forEach((key) => {
        flattenedAspects[key] = product.aspects[key].join(', ');
      });
  
      return {
        title,
        sku,
        productTitle: product.title,
        description: product.description, // Assuming description contains HTML and exporting as is
        imageUrl: product.imageUrls.join(', '), // Joining image URLs into a single string
        ...flattenedAspects, // Spread the flattened aspects into the final object
      };
    });
  };

// function convertJsonToCsv(data) {
//     const array = Array.isArray(data) ? data : JSON.parse(data);
//     let str = '';
//     let headers = [];

//     // Collect all headers
//     array.forEach((item) => {
//         const flattenedItem = flattenObject(item);
//         headers = headers.concat(Object.keys(flattenedItem));
//     });

//     // Remove duplicates and create the header row
//     headers = [...new Set(headers)];
//     const oldheaders=[...new Set(headers)];
//     for(var i=0;i<headers.length;i++)
//       {
//         headers[i]=headers[i].split('.').pop(); 
//       }
//     str += headers.join(',') + '\n';
//     // Process each item
//     array.forEach((item) => {
//         const flattenedItem = flattenObject(item);
//         let line = '';

//         oldheaders.forEach((header) => {
//             if (line !== '') line += ',';

//             const value = flattenedItem[header];
//             if (value === undefined) {
//                 line += '';
//             } else if (Array.isArray(value)) {
//                 line += `"${value.join(';')}"`;
//             } else {
//                 line += `"${value}"`;
//             }
//         });

//         str += line + '\n';
//     });

//     return str;
// }

  function ExportToCsv() {
    var data=inventoryItems;
    console.log('data',data);
    try {
      // Flatten the data for the Excel sheet
      const flattenedData = flattenData(data);
      console.log('Flattened Data:', flattenedData);

      // Create a worksheet from the flattened data
      const worksheet = XLSX.utils.json_to_sheet(flattenedData);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Inventory');

      // Generate a binary string representation of the workbook
      const xlsBinary = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

      // Convert the binary string to an ArrayBuffer
      const buffer = new ArrayBuffer(xlsBinary.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < xlsBinary.length; i++) {
        view[i] = xlsBinary.charCodeAt(i) & 0xff;
      }

      // Create a Blob from the ArrayBuffer
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a link to download the Blob
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'inventory_output.xlsx';

      // Append the link to the document and trigger a click
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      document.body.removeChild(link);

      console.log('XLSX file has been saved successfully.');
    } catch (error) {
      console.error('Error exporting to XLS:', error);
    }
    // try {
  
    //   // Convert JSON data to CSV format
      
    //   const csv = convertJsonToCsv(data);
    //   console.log(data);
  
    //   // Create a Blob object containing the CSV data
    //   const blob = new Blob([csv], { type: 'text/csv' });
  
    //   // Create a temporary anchor element to trigger the download
    //   const link = document.createElement('a');
    //   link.href = window.URL.createObjectURL(blob);
    //   link.download = 'output.csv';
  
    //   // Programmatically click the link to trigger the download
    //   link.click();
  
    //   console.log('CSV file has been saved successfully.');
    // } catch (error) {
    //   console.error('Error exporting to CSV:', error);
    // }
  }
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="tog-sidebar">
    <div className="inventory-container">
      <div className="inventrory-header">
        <h2>Products</h2>
        {/* <a href="#">
          Select another Store{" "}
          <span>
            <img src="/ebay.png" alt="ebay" className="store-img"/>
          </span>
        </a> */}
      </div>
      <div className="search-create-container">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search SKU or Title"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <div className="search-icon">
            <i className="fas fa-search"></i>
          </div>

          {/* <button onClick={clearSearch}>Clear</button> */}
        </div>
        <div className="create-product">
          <button onClick={ExportToCsv}>Export As CSV</button>
          <Link to="/productListing">
            <button>Create Product</button>
          </Link>

        </div>
      </div>
      <div className="inventory-table">
        <table className="table">
          <thead>
            <tr className="table-rows">
              <th className="table-header">S.No</th>
              <th className="table-header">Title</th>
              <th className="table-header">SKU</th>
              <th className="table-header">Actions</th>
            </tr>
          </thead>
          {/* <tbody>
          {inventoryItems.map(item => (
            <tr key={item.sku}>
              <td className="table-data-cell">{item.title}</td>
              <td className="table-data-cell">{item.sku}</td>
              <td className="table-data-cell">
                <button onClick={() => editItem(item.sku)}>Edit</button>
                <button onClick={() => viewItem(item.sku)}>View</button>
                <button onClick={() => deleteItem(item.sku)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody> */}
          {/* <tbody>
          {Array.isArray(inventoryItems) && inventoryItems.length > 0 ? (
            inventoryItems.map(item => (
              <tr key={item.sku}>
                <td className="table-data-cell">{item.title}</td>
                <td className="table-data-cell">{item.sku}</td>
                <td className="table-data-cell">
                  <button onClick={() => editItem(item.sku)}>Edit</button>
                  <button onClick={() => viewItem(item.sku)}>View</button>
                  <button onClick={() => showDeleteConfirmation(item.sku)}>Delete</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="table-data-cell" colSpan="3">No inventory items found</td>
            </tr>
          )}
        </tbody> */}

          <tbody>
            {/* Array.isArray(filteredItems) && filteredItems.length > 0 */}
            {Array.isArray(filteredItems) && filteredItems.length > 0 ? (
            filteredItems.map((item,index) => (
                <tr key={item.sku}>
                  <td className="table-data-cell">{index + 1}</td>
                  <td className="table-data-cell">{item.title}</td>
                  <td className="table-data-cell">{item.sku}</td>
                  <td className="table-data-cell">
                    <div>
                      <button
                        className="table-action-btn"
                        onClick={() => editItem(item.sku)}
                      >
                        Edit
                      </button>
                      <button
                        className="table-action-btn"
                        onClick={() => viewItem(item.sku)}
                      >
                        View
                      </button>
                      <button
                        className="table-action-btn"
                        onClick={() => showDeleteConfirmation(item.sku)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="table-data-cell" colSpan="4">
                  No items found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="pagination">
        {Array.from(
          { length: Math.ceil(filteredItems.length / itemsPerPage) },
          (_, index) => (
            <button key={index} onClick={() => paginate(index + 1)}>
              {index + 1}
            </button>
          )
        )}
      </div>
      {showDeleteModal && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-content">
              <p className='table-data-cell'>Are you sure you want to delete this item?</p>
              <button className='table-action-btn' onClick={() => deleteItem(itemToDelete)}>Confirm</button>
              <button className='table-action-btn' onClick={hideDeleteConfirmation}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default InventoryTable;
