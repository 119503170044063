import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./Information.css";
import "./../styles.css";
import axios from 'axios';

const LocationPolicyForm = () => {
  const store_id = localStorage.getItem('store_id');
  const token = localStorage.getItem('token');
  const [merchantLocationKey,setmerchantLocationKey] = useState(null);
  const [formData, setFormData] = useState({
    locationAddress: {
      line1: "",
      line2: "",
    },
    city: "",
    stateOrProvince: "",
    postalCode: "",
    country: "",
    locationInstructions: "",
    locationName: "",
    phoneNumber: "",
    merchantLocationStatus: "",
    locationType: "",
    operatingHours: "",
    openingHours: "",
    closingHours: "",
    specialHours: "",
  });
  const [formData1, setFormData1] = useState({
    location: {
      address: {
        addressLine1: "2055 Hamilton Ave",
        addressLine2: "Building 3",
        city: "San Jose",
        stateOrProvince: "CA",
        postalCode: "95125",
        country: "US",
      },
    },
    locationInstructions: "Open for drop-off only.",
    name: "Warehouse-1",
    phone: "123-456-7890",
    merchantLocationStatus: "ENABLED",
    locationTypes: ["WAREHOUSE"],
    operatingHours: [
      {
        dayOfWeek: "MONDAY",
        intervals: [
          {
            open: "09:00:00",
            close: "12:00:00",
          },
          {
            open: "13:00:00",
            close: "18:00:00",
          },
        ],
      },
      {
        dayOfWeek: "TUESDAY",
        intervals: [
          {
            open: "09:00:00",
            close: "15:00:00",
          },
        ],
      },
    ],
    specialHours: [
      {
        date: "2016-10-19T00:09:00.000Z",
        intervals: [
          {
            open: "09:00:00",
            close: "11:00:00",
          },
        ],
      },
    ],
  });
  
  const [error, setError] = useState(null);
  const [expandedMerchantLocationStatus, setExpandedMerchantLocationStatus] =
    useState(false);
  const [expandedLocationType, setExpandedLocationType] = useState(false);
  const [expandedOperatingHours, setExpandedOperatingHours] = useState(false);
  const navigate = useNavigate();

  const toggleMerchantLocationStatus = () => {
    setExpandedMerchantLocationStatus(
      (prevExpandedMerchantLocationStatus) =>
        !prevExpandedMerchantLocationStatus
    );
  };

  const toggleLocationType = () => {
    setExpandedLocationType(
      (prevExpandedLocationType) => !prevExpandedLocationType
    );
  };

  const toggleOperatingHours = () => {
    setExpandedOperatingHours(
      (prevExpandedOperatingHours) => !prevExpandedOperatingHours
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData1);

      const response = await fetch(`/api/v1/createLocation/${merchantLocationKey}`,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: formData1,
          token: token
        })
      });
      console.log("waiting for response");
      if(!response.ok)
        {
          if (response.status === 301) {
            // Token expired
            alert('Session expired,Login Again');
            localStorage.removeItem('token')
            navigate('/');
          } else if (response.status === 302 || response.status === 303) {
            // Invalid token or other client-side error
            alert('Invalid Session, Login Again');
            localStorage.removeItem('token')
            navigate('/');
          } 
        }
      console.log("After response, response status: ", response.ok);
      const data = await response.json();
      localStorage.setItem('token', data.token)
      
      console.log("Location policy created successfully:", data.message);
      toast.success("Location policy created successfully!");
      // navigate("/Admin/policieslocations/Location");
    } catch (err) {
      console.error("Error:", err);
      setError("Error creating location policy");
    }
  };

  const inputStyle = {
    width: "100%",
    height: "60px",
    background: "#FFFFFF",
    border: "none",
    boxShadow: "0px 5px 5px 0px #00000040",
    borderRadius: "10px",
    marginBottom: "3rem",
    padding: "1rem",
    boxSizing: "border-box",
  };

  const buttonStyle = {
    cursor: "pointer",
    height: "60px",
    background: "#142B41",
    boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    color: "#FFFFFF",
    marginTop: "2rem",
  };

  const errorMessageStyle = {
    color: "red",
    margin: "5px 0",
  };

  return (
    <div id="tog-sidebar">
    <div className="location-policy-form">
      <div className="location-policy-form-left">
        <h1>Location Policy</h1>
        <form onSubmit={handleSubmit}>
        <label htmlFor="location">Merchant Location Key</label>
        <input
            type="text"
            id="location"
            placeholder="Please enter the merchant Location Key."
            value={merchantLocationKey}
            onChange={(e) => setmerchantLocationKey(e.target.value)}
            style={inputStyle}
          />

          <label htmlFor="line1">Location Address</label>
          <input
            type="text"
            id="line1"
            placeholder="Please enter the first line of the address."
            value={formData.locationAddress.line1}
            onChange={(e) =>
              setFormData({
                ...formData,
                locationAddress: {
                  ...formData.locationAddress,
                  line1: e.target.value,
                },
              })
            }
            style={inputStyle}
          />
          <input
            type="text"
            id="line2"
            placeholder="Please enter the second line of the address."
            value={formData.locationAddress.line2}
            onChange={(e) =>
              setFormData({
                ...formData,
                locationAddress: {
                  ...formData.locationAddress,
                  line2: e.target.value,
                },
              })
            }
            style={inputStyle}
          />

          <label htmlFor="city">City</label>
          <input
            type="text"
            id="city"
            placeholder="Enter city"
            value={formData.city}
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            style={inputStyle}
          />

          <label htmlFor="stateOrProvince">State or Province</label>
          <input
            type="text"
            id="stateOrProvince"
            placeholder="Enter state or province"
            value={formData.stateOrProvince}
            onChange={(e) =>
              setFormData({ ...formData, stateOrProvince: e.target.value })
            }
            style={inputStyle}
          />

          <label htmlFor="postalCode">Postal Code</label>
          <input
            type="text"
            id="postalCode"
            placeholder="Enter postal code"
            value={formData.postalCode}
            onChange={(e) =>
              setFormData({ ...formData, postalCode: e.target.value })
            }
            style={inputStyle}
          />

          <label htmlFor="country">Country</label>
          <input
            type="text"
            id="country"
            placeholder="Enter country"
            value={formData.country}
            onChange={(e) =>
              setFormData({ ...formData, country: e.target.value })
            }
            style={inputStyle}
          />

          <label htmlFor="locationInstructions">Location Instructions</label>
          <textarea
            id="locationInstructions"
            placeholder="Enter location instructions"
            value={formData.locationInstructions}
            onChange={(e) =>
              setFormData({ ...formData, locationInstructions: e.target.value })
            }
            style={inputStyle}
          />

          <label htmlFor="locationName">Location Name</label>
          <input
            type="text"
            id="locationName"
            placeholder="Enter location name"
            value={formData.locationName}
            onChange={(e) =>
              setFormData({ ...formData, locationName: e.target.value })
            }
            style={inputStyle}
          />

          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            type="tel"
            id="phoneNumber"
            placeholder="Enter phone number"
            value={formData.phoneNumber}
            onChange={(e) =>
              setFormData({ ...formData, phoneNumber: e.target.value })
            }
            style={inputStyle}
          />

          {/* Merchant Location Status */}
          <div
            className="custom-select-container"
            style={{ height: expandedMerchantLocationStatus ? "300px" : "" }}
          >
            <label htmlFor="merchantLocationStatus">
              Merchant Location Status
            </label>
            <div
              className={`custom-select ${
                expandedMerchantLocationStatus ? "active" : ""
              }`}
            >
              <button
                type="button"
                className="select-button"
                onClick={() =>
                  toggleMerchantLocationStatus(
                    "merchantLocationStatus",
                    !formData.merchantLocationStatus
                  )
                }
              >
                <span
                  className={`selected-value ${
                    formData.merchantLocationStatus !== "" &&
                    formData.merchantLocationStatus !== "Select"
                      ? "selected-black"
                      : ""
                  }`}
                >
                  {formData.merchantLocationStatus
                    ? formData.merchantLocationStatus
                    : "Select"}
                </span>
                <span className="arrow"></span>
              </button>
              <ul className="select-dropdown">
                <li>
                  <label htmlFor="merchantLocationStatusEnabled">
                    ENABLED
                    <input
                      type="radio"
                      id="merchantLocationStatusEnabled"
                      name="merchantLocationStatus"
                      value="ENABLED"
                      checked={formData.merchantLocationStatus === "ENABLED"}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          merchantLocationStatus: "ENABLED",
                        })
                      }
                    />
                  </label>
                </li>
                <li>
                  <label htmlFor="merchantLocationStatusDisabled">
                  DISABLED
                    <input
                      type="radio"
                      id="merchantLocationStatusDisabled"
                      name="merchantLocationStatus"
                      value="DISABLED"
                      checked={formData.merchantLocationStatus === "DISABLED"}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          merchantLocationStatus: "DISABLED",
                        })
                      }
                    />
                  </label>
                </li>
              </ul>
            </div>
          </div>

          {/* Location Type */}
          <div
            className="custom-select-container"
            style={{ height: expandedLocationType ? "350px" : "" }}
          >
            <label htmlFor="locationType">Location Type</label>
            <div
              className={`custom-select ${
                expandedLocationType ? "active" : ""
              }`}
            >
              <button
                type="button"
                className="select-button"
                onClick={() => toggleLocationType()}
              >
                <span
                  className={`selected-value ${
                    formData.locationType !== "" &&
                    formData.locationType !== "Select"
                      ? "selected-black"
                      : ""
                  }`}
                >
                  {formData.locationType ? formData.locationType : "Select"}
                </span>
                <span className="arrow"></span>
              </button>
              <ul className="select-dropdown">
                <li>
                  <label htmlFor="locationTypeWarehouse">
                    WAREHOUSE
                    <input
                      type="radio"
                      id="locationTypeWarehouse"
                      name="locationType"
                      value="WAREHOUSE"
                      checked={formData.locationType === "WAREHOUSE"}
                      onChange={() =>
                        setFormData({ ...formData, locationType: "WAREHOUSE" })
                      }
                    />
                  </label>
                </li>
                <li>
                  <label htmlFor="locationTypeStore">
                    STORE
                    <input
                      type="radio"
                      id="locationTypeStore"
                      name="locationType"
                      value="STORE"
                      checked={formData.locationType === "STORE"}
                      onChange={() =>
                        setFormData({ ...formData, locationType: "STORE" })
                      }
                    />
                  </label>
                </li>
                <li>
                  <label htmlFor="locationTypeOffice">
                    Office
                    <input
                      type="radio"
                      id="locationTypeOffice"
                      name="locationType"
                      value="Office"
                      checked={formData.locationType === "Office"}
                      onChange={() =>
                        setFormData({ ...formData, locationType: "Office" })
                      }
                    />
                  </label>
                </li>
              </ul>
            </div>
          </div>

          {/* Operating Hours */}
          <div
            className="custom-select-container"
            style={{ height: expandedOperatingHours ? "350px" : "" }}
          >
            <label htmlFor="operatingHours">Operating Hours</label>
            <div
              className={`custom-select ${
                expandedOperatingHours ? "active" : ""
              }`}
            >
              <button
                type="button"
                className="select-button"
                onClick={() => toggleOperatingHours()}
              >
                <span
                  className={`selected-value ${
                    formData.operatingHours !== "" &&
                    formData.operatingHours !== "Select"
                      ? "selected-black"
                      : ""
                  }`}
                >
                  {formData.operatingHours ? formData.operatingHours : "Select"}
                </span>
                <span className="arrow"></span>
              </button>
              <ul className="select-dropdown">
                <li>
                  <label htmlFor="operatingHoursMonday">
                    Monday
                    <input
                      type="radio"
                      id="operatingHoursMonday"
                      name="operatingHours"
                      value="Monday"
                      checked={formData.operatingHours === "Monday"}
                      onChange={() =>
                        setFormData({ ...formData, operatingHours: "Monday" })
                      }
                    />
                  </label>
                </li>
                <li>
                  <label htmlFor="operatingHoursTuesday">
                    Tuesday
                    <input
                      type="radio"
                      id="operatingHoursTuesday"
                      name="operatingHours"
                      value="Tuesday"
                      checked={formData.operatingHours === "Tuesday"}
                      onChange={() =>
                        setFormData({ ...formData, operatingHours: "Tuesday" })
                      }
                    />
                  </label>
                </li>
                <li>
                  <label htmlFor="operatingHoursWednesday">
                    Wednesday
                    <input
                      type="radio"
                      id="operatingHoursWednesday"
                      name="operatingHours"
                      value="Wednesday"
                      checked={formData.operatingHours === "Wednesday"}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          operatingHours: "Wednesday",
                        })
                      }
                    />
                  </label>
                </li>
              </ul>
            </div>
          </div>

          <label htmlFor="openingHours">Opening Hours</label>
          <input
            type="text"
            id="openingHours"
            placeholder="Enter opening hours"
            value={formData.openingHours}
            onChange={(e) =>
              setFormData({ ...formData, openingHours: e.target.value })
            }
            style={inputStyle}
          />

          <label htmlFor="closingHours">Closing Hours</label>
          <input
            type="text"
            id="closingHours"
            placeholder="Enter closing hours"
            value={formData.closingHours}
            onChange={(e) =>
              setFormData({ ...formData, closingHours: e.target.value })
            }
            style={inputStyle}
          />

          <label htmlFor="specialHours">Special Hours</label>
          <input
            type="text"
            id="specialHours"
            placeholder="Enter special hours"
            value={formData.specialHours}
            onChange={(e) =>
              setFormData({ ...formData, specialHours: e.target.value })
            }
            style={inputStyle}
          />

          <button className="next-btn" type="submit" style={buttonStyle}>
            Save
          </button>

          {error && (
            <p className="error-message" style={errorMessageStyle}>
              {error}
            </p>
          )}
        </form>
      </div>
    </div>
    </div>
  );
};

export default LocationPolicyForm;
