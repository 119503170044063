import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';

const SignUpCard = () => {
  const [signupname, setSignupname] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [address, setAddress] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // State for loading
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true when the form is submitted
  
    try {
      const response = await axios.post('/api/v1/signup', {
        name: signupname,
        contact: contact,
        email: email,
        password: password,
        address: address,
        companyName: companyName
      });
  
      console.log(response);
  
      localStorage.setItem('user_id', response.data.user_id);
      localStorage.setItem('company_id', response.data.company_id);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('store_id', response.data.store_id);
      localStorage.setItem('marketplace_id',response.data.marketplace_id);
  
      setEmail('');
      setPassword('');
      setError(null);
      setIsLoading(false); // Set loading to false after successful signup
  
      if (response.data.store_id === 0) {
        navigate('/store-integration');
      } else {
        navigate('/allproducts');
      }
    } catch (err) {
      setIsLoading(false); // Set loading to false if there's an error
      
      // Display an alert message with the appropriate error message
      if (err.response) {
        if(err.response.status === 401){
          window.alert("Company name already exists.");
        }
        else if (err.response.status === 400) {
          window.alert('User already exists with this email.');
        } else {
          window.alert('An error occurred. Please try again later.');
        }
      } else {
        window.alert('Network error. Please check your internet connection and try again.');
      }
    }
  };
  
  
  const errorMessageStyle = {
    color: "red",
    margin: "5px 0",
  };
  const buttonStyle = {
    padding: "10px",
    backgroundColor: "#fff",
    color: "#333",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    width: "100%",
    fontSize: "16px",
    fontweight: "bold",
    marginTop: "10px",
  };
  return (
    <div className="login-wrapper">
      <div className="login-form">
        <h3>
          Welcome to <span>ThriftOps</span>
        </h3>
        <form onSubmit={handleSubmit}>
          <div>
            <input
              type="text"
              placeholder="Name"
              value={signupname}
              onChange={(e) => setSignupname(e.target.value)}
              required
            />
          </div>
          <div>
            <input
              type="phone"
              placeholder="Contact Number"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              required
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
          </div>
          <div>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <br/>
          {error && <p style={errorMessageStyle}>{error}</p>}
          <button type="submit" style={buttonStyle} disabled={isLoading}>
            {isLoading ? "Loading......" : "Sign Up"}
          </button>
          {/* Loader component */}
        
        </form>
        <p className="login-register-link">
          Already have an account? <Link to="/" className="register">Login</Link>
        </p>
      </div>
      <div className="img-container">
        <img src="/login.jpeg" alt="signup-img" className="login-img" />
      </div>
    </div>
  );
};

export default SignUpCard;
