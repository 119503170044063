import React from "react";
import "./ListCategories.css";
const ListCategories = ({
  handleBackButtonClick,
  data,
  TreeNode,
  getAspects,
  LoadingState,
  setLoadingState,
}) => {
  return (
    
    <div className="list-categories-wrapper">
      {/* Collapsible row for the previous step */}
      {console.log(data)}
      <h1>List of Categories</h1>
      <div className="">
        {/* <div>
          <button className="button-back" onClick={handleBackButtonClick}>
            Back
          </button>
        </div> */}

        <div className="tree-sample">
          {data && Object.keys(data).length !== 0
            ? data.map((x, index) => (
                <TreeNode key={index} node={x} />
                
              ))
            : null}
        </div>
        <button
          type="button" // Prevents form submission
          className="button-submit ebay-Login"
          onClick={() => {
            setLoadingState((prev) => ({ ...prev, listingCategories: true }));

            getAspects();
          }}
        >
          {LoadingState?.listingCategories
            ? "Listing Product.."
            : "List Product"}
        </button>
      </div>
    </div>
  );
};

export default ListCategories;
