import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./Information.css";
import axios from 'axios';
import "./../styles.css"

const SetDefaultForm = () => {
  const navigate = useNavigate();
  const store_id = localStorage.getItem('store_id');
  const [loading, setLoading] = useState(false);
  const [storeInfo, setStoreInfo] = useState(null);
  const [formData, setFormData] = useState({
    marketplaceId: "",
    currency: "",
    businessPolicy: "",
    paymentPolicy: "",
    fulfillmentPolicy: "",
    returnPolicy: "",
    locations: "",
    store_id: store_id , // Set default value for companyId
    payment_policy_id: "",
    fulfillment_policy_id: "",
    return_policy_id: "",
    locations_id: ""
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value === "Create Fulfillment") {
      navigate("/admin/createfulfillmentpolicy");
    } else if (value === "Create Payment") {
      navigate("/admin/createpaymentpolicy");
    } else if (value === "Create Return") {
      navigate("/admin/createreturnpolicy");
    } else if (value === "Create Business") {
      navigate("/admin/createbusinesspolicy");
    } else if (value === "Create Location") {
      navigate("/admin/createlocation");
    } else {
      setFormData({ ...formData, [name]: value });
      
    }
  };
  
  
  useEffect(() => {
    async function fetchStoreInfo() {
      try {
        console.log("checking set Default function 'fetching store info");
        const store_id = localStorage.getItem("store_id");
        console.log("storeID", store_id);
        // This is fetching information from eBay through APIs like marketplaceID, and different policies - This is independant of information saved in database.
        // This information will fill the dropdowns etc

        const token = localStorage.getItem('token');
     

        // Fetch data from your server API endpoint (replace with your actual API endpoint)
        const response = await fetch('/api/v1/storeInfo', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: token
          })
        });
        console.log("waiting for response");
        if(!response.ok)
          {
            if (response.status === 301) {
              // Token expired
              alert('Session expired,Login Again');
              localStorage.removeItem('token')
              navigate('/');
            } else if (response.status === 302 || response.status === 303) {
              // Invalid token or other client-side error
              alert('Invalid Session, Login Again');
              localStorage.removeItem('token')
              navigate('/');
            } 
          }
        
        const data = await response.json();
        console.log("After response response status: ", response.ok);
        
        localStorage.setItem('token', data.token)
        console.log(data.data);
        setStoreInfo(data.data);
        
        console.log("store data", storeInfo);
        

        
        const token1 = localStorage.getItem('token');
      
        // Fetch company details based on company ID and this information is set in the database. This will set the default values in the form.
        // needs Edit: When the functionality of multiple stores on same company will be added, then here store ID must be sent to the server
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Adjust the content type if necessary
            // Add any other headers if required
          },
          body: JSON.stringify({
            token: token1
          })
        };
        console.log(token1);

        console.log(store_id);
        const companyResponse = await fetch(`/api/v1/company`, requestOptions);

        console.log("waiting for response");
        if(!companyResponse.ok)
          {
            if (companyResponse.status === 301) {
              // Token expired
              alert('Session expired,Login Again');
              localStorage.removeItem('token')
              navigate('/');
            } else if (companyResponse.status === 302 || companyResponse.status === 303) {
              // Invalid token or other client-side error
              alert('Invalid Session, Login Again');
              localStorage.removeItem('token')
              navigate('/');
            } 
          }
        
        const Data = await companyResponse.json();
        
        localStorage.setItem('token', Data.token)
          
        const companyData = Data.data;
        
  
        // Set default values for policies and locations
        setFormData(prevFormData => ({
          ...prevFormData,
          businessPolicy:companyData.business_policy_type,
          paymentPolicy:companyData.payment_policy_name,
          payment_policy_id: companyData.payment_policy_id,
          fulfillment_policy_id: companyData.fulfillment_policy_id,
          fulfillmentPolicy: companyData.fulfillment_policy_name,
          return_policy_id: companyData.return_policy_id,
          returnPolicy: companyData.return_policy_name,
          locations_id: companyData.location_id,
          locations: companyData.location_name,
        }));
      } catch (error) {
        alert("Failed to fetch store details. Try again");
        console.error('Error:', error);
      }
    }
  
    fetchStoreInfo();
  }, [formData.companyId]);
  

  const [error, setError] = useState(null);
  const [expandedMarketplaceId, setExpandedMarketplaceId] = useState(false);
  const [expandedCurrency, setExpandedCurrency] = useState(false);
  const [expandedBusinessPolicy, setExpandedBusinessPolicy] = useState(false);
  const [expandedPaymentPolicy, setExpandedPaymentPolicy] = useState(false);
  const [expandedFulfillmentPolicy, setExpandedFulfillmentPolicy] =
    useState(false);
  const [expandedReturnPolicy, setExpandedReturnPolicy] = useState(false);
  const [expandedLocation, setExpandedLocation] = useState(false);
  

  const toggleSelectMarketplaceId = () => {
    setExpandedMarketplaceId((prevExpanded) => !prevExpanded);
  };

  const toggleSelectCurrency = () => {
    setExpandedCurrency((prevExpanded) => !prevExpanded);
  };

  const toggleSelectBusinessPolicy = () => {
    setExpandedBusinessPolicy((prevExpanded) => !prevExpanded);
  };

  const toggleSelectPaymentPolicy = () => {
    setExpandedPaymentPolicy((prevExpanded) => !prevExpanded);
  };

  const toggleSelectFulfillmentPolicy = () => {
    setExpandedFulfillmentPolicy((prevExpanded) => !prevExpanded);
  };

  const toggleSelectReturnPolicy = () => {
    setExpandedReturnPolicy((prevExpanded) => !prevExpanded);
  };
  const toggleSelectLocation = () => { // Changed to toggleSelectLocation
    setExpandedLocation((prevExpanded) => !prevExpanded); // Changed to expandedLocation
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      console.log(formData);
      
      const token = localStorage.getItem('token');
     
      
      localStorage.setItem("marketplace_id", formData.marketplaceId);

      const response = await fetch(`/api/v1/setDefaultValues`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({data: formData,token: token}),
      });

      if(!response.ok)
        {
          if (response.status === 301) {
            // Token expired
            alert('Session expired,Login Again');
            localStorage.removeItem('token')
            navigate('/');
          } else if (response.status === 302 || response.status === 303) {
            // Invalid token or other client-side error
            alert('Invalid Session, Login Again');
            localStorage.removeItem('token')
            navigate('/');
          } 
        }
      
      if (response.ok) {

        console.log("After response response status: ", response.ok);
        const data = await response.json();
        
        localStorage.setItem('token', data.token)
        
        console.log("Default values set successfully:", data.data);
        toast.success("Default values set successfully!");
        alert("Successfully Updated your default values!");
        navigate("/allproducts");
      } else {
        console.error("Failed to set default values:", response.status);
        alert("Failed to set default values. Try Again");
        toast.error("Failed to set default values");
      }
    } catch (err) {
      console.error("Error:", err);
      alert("There is some error in setting the default value.");
      setError("Error setting default values");
    }
    finally {
      setLoading(false); // Reset loading state whether success or error
    }
  };

  const buttonStyle = {
    width: "100%",
    cursor: "pointer",
    height: "60px",
    background: "#142B41",
    boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "22px",
    color: "#FFFFFF",
    marginTop: "1rem",
  };

  const errorMessageStyle = {
    color: "red",
    margin: "5px 0",
  };

  return (
    <div id="tog-sidebar">  
      
    <div className="main-container">
        <div className="set-default-form">
        
        {storeInfo ? (
          <div className="set-default-form-left">
            <h1>Set Defaults</h1>
           {/* Diagonal Arrow Button */}
          
  
            <form onSubmit={handleSubmit}>
             {/* Marketplace ID */}
             <div className="custom-select-container">
                <label htmlFor="marketplaceId">Marketplace ID</label>
                <div className="custom-select">
                  <span className="selected-value">
                    {formData.marketplaceId = storeInfo.MarketplaceId ? storeInfo.MarketplaceId : "Loading..."}
                    
                  </span>
                  <ul className="select-dropdown">
                    <li>
                      <label htmlFor="marketplaceIdThriftOpsDefault">
                        <input
                          type="radio"
                          id="marketplaceIdEBAY_US"
                          name="marketplaceId"
                          value="Ebay US"
                          checked={formData.marketplaceId === "Ebay US"}
                          onChange={(e) => e.preventDefault()}
                          disabled
                        />
                        Ebay US
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
  
              {/* Currency */}
              <div className="custom-select-container">
                <label htmlFor="currency">Currency</label>
                <div className="custom-select">
                  <span className="selected-value">
                    {formData.currency = storeInfo.defaultCurrency ? storeInfo.defaultCurrency : "Loading..."}
                   
                  </span>
                 
                </div>
              </div>
  
             
  
            {/* Business Policy */}
            <div
              className="custom-select-container"
              style={{ height: expandedBusinessPolicy ? "300px" : "" }}
            >
              <label htmlFor="businessPolicy">Business Policy</label>
              <div
                className={`custom-select ${
                  expandedBusinessPolicy ? "active" : ""
                }`}
              >
                <button
                  type="button"
                  className="select-button"
                  onClick={toggleSelectBusinessPolicy}
                  aria-expanded={expandedBusinessPolicy}
                >
                  <span className="selected-value">
                    {formData.businessPolicy
                      ? formData.businessPolicy
                      : "Select"}
                  </span>
                  <span className="arrow"></span>
                </button>
                <ul className="select-dropdown">
                  {storeInfo.program.map((program, index) => (
                    <li key={index}>
                      <label htmlFor={`program${index}`}>
                        <input
                          type="radio"
                          id={`program${index}`}
                          name="program"
                          value={program.programType}
                          checked={formData.businessPolicy  === program.programType}
                          onChange={(e) =>
                            setFormData({ ...formData, businessPolicy: e.target.value })
                          }
                        />
                        {program.programType}
                      </label>
                    </li>
                  ))}
                  {/* Conditionally render "Create" option */}
                  {storeInfo.program.length === 0 && (
                    <li>
                      <label htmlFor="businessPolicyCreateLink">
                        <input
                          type="radio"
                          id="businessPolicyCreateLink"
                          name="businessPolicy"
                          value="Create Business"
                          checked={formData.businessPolicy === "Create Business"}
                          onChange={handleChange}
                        />
                        Create 
                      </label>
                    </li>
                  )}
                </ul>
              </div>
            </div>
  
  
            {storeInfo.program.length !== 0 && (
              <div>
              {/* Payment Policy */}
              <div
                className="custom-select-container"
                style={{ height: expandedPaymentPolicy ? "300px" : "" }}
              >
                <label htmlFor="paymentPolicy">Payment Policy
                &nbsp;&nbsp;
                <a href="/Admin/policieslocations/PaymentPolicy" target="_blank" class="diagonal-arrow-button">
                  &#8599;
                </a>
                </label>
                <div
                  className={`custom-select ${
                    expandedPaymentPolicy ? "active" : ""
                  }`}
                >
                  <button
                    type="button"
                    className="select-button"
                    onClick={toggleSelectPaymentPolicy}
                    aria-expanded={expandedPaymentPolicy}
                  >
                    <span className="selected-value">
                      {formData.paymentPolicy ? formData.paymentPolicy : "Select"}
                    </span>
                    <span className="arrow"></span>
                  </button>
                  <ul className="select-dropdown">
                  {storeInfo.paymentPolicies.paymentPolicies.map(policy => (
                      <li key={policy.paymentPolicyId}>
                        <label htmlFor={`paymentPolicy${policy.paymentPolicyId}`}>
                          <input
                            type="radio"
                            id={`paymentPolicy${policy.paymentPolicyId}`}
                            name="paymentPolicy"
                            value={policy.name}
                            checked={formData.paymentPolicy === policy.name}
                            onChange={(e) =>
                              setFormData({ ...formData, payment_policy_id: policy.paymentPolicyId, paymentPolicy: e.target.value })
                              
                            }
                          />
                          {policy.name}
                        </label>
                      </li>
                    ))}
                   
                    <li>
                      <label htmlFor="paymentPolicyCreateLink">
                      <input
                          type="radio"
                          id="paymentPolicyCreateLink"
                          name="paymentPolicy"
                          value="Create Payment"
                          checked={formData.paymentPolicy === "Create Payment"}
                          onChange={handleChange}
                        />
                        Create 
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
  
              {/* Fulfillment Policy */}
              <div
                className="custom-select-container"
                style={{ height: expandedFulfillmentPolicy ? "300px" : "" }}
              >
                <label htmlFor="fulfillmentPolicy">Fulfillment Policy
                &nbsp;&nbsp;
                <a href="/Admin/policieslocations/FulfillmentPolicy" target="_blank" class="diagonal-arrow-button">
                  &#8599;
                </a>
                </label>
                <div
                  className={`custom-select ${
                    expandedFulfillmentPolicy ? "active" : ""
                  }`}
                >
                  <button
                    type="button"
                    className="select-button"
                    onClick={toggleSelectFulfillmentPolicy}
                    aria-expanded={expandedFulfillmentPolicy}
                  >
                    <span className="selected-value">
                      {formData.fulfillmentPolicy
                        ? formData.fulfillmentPolicy
                        : "Select"}
                    </span>
                    <span className="arrow"></span>
                  </button>
                  <ul className="select-dropdown">
                  {storeInfo.fulfillmentPolicies.fulfillmentPolicies.map(policy => (
                    <li key={policy.fulfillmentPolicyId}>
                      <label htmlFor={`fulfillmentPolicy${policy.fulfillmentPolicyId}`}>
                        <input
                          type="radio"
                          id={`fulfillmentPolicy${policy.fulfillmentPolicyId}`}
                          name="fulfillmentPolicy"
                          value={policy.name}
                          checked={formData.fulfillmentPolicy === policy.name}
                          onChange={(e) =>
                            setFormData({ ...formData, fulfillment_policy_id: policy.fulfillmentPolicyId, fulfillmentPolicy: e.target.value })
                          }
                        />
                        {policy.name}
                      </label>
                    </li>
                  ))}
                    
                    <li>
                      <label htmlFor="fulfillmentPolicyCreateLink">
                      <input
                            type="radio"
                            id="fulfillmentPolicyCreateLink"
                            name="fulfillmentPolicy"
                            value="Create Fulfillment"
                            checked={formData.fulfillmentPolicy === "Create Fulfillment"}
                            onChange={handleChange}
                          />
                      
                        Create 
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
  
              {/* Return Policy */}
              <div className="custom-select-container" style={{ height: expandedReturnPolicy ? "300px" : "" }}>
                <label htmlFor="return">Return Policy
                &nbsp;&nbsp;
                <a href="/Admin/policieslocations/ReturnPolicy" target="_blank" class="diagonal-arrow-button">
                  &#8599;
                </a>
                </label>
                <div className={`custom-select ${expandedReturnPolicy ? "active" : ""}`}>
                  <button type="button" className="select-button" onClick={toggleSelectReturnPolicy} aria-expanded={expandedReturnPolicy}>
                    <span className="selected-value">{formData.returnPolicy ? formData.returnPolicy : "Select"}</span>
                    <span className="arrow"></span>
                  </button>
                  <ul className="select-dropdown">
                    {storeInfo.returnPolicies.returnPolicies.map(policy => (
                      <li key={policy.returnPolicyId}>
                        <label htmlFor={`returnPolicy${policy.returnPolicyId}`}>
                          <input
                            type="radio"
                            id={`returnPolicy${policy.returnPolicyId}`}
                            name="returnPolicy"
                            value={policy.name}
                            checked={formData.returnPolicy === policy.name}
                            onChange={(e) => setFormData({ ...formData, return_policy_id: policy.returnPolicyId,returnPolicy: e.target.value })}
                          />
                          {policy.name}
                        </label>
                      </li>
                    ))}
                    <li>
                      <label htmlFor="returnPolicyCreateLink">
                        <input
                          type="radio"
                          id="returnPolicyCreateLink"
                          name="returnPolicy"
                          value="Create Return"
                          checked={formData.returnPolicy === "Create Return"}
                          onChange={handleChange}
                        />
                        Create
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
  
              {/* Location */}
              <div className="custom-select-container" style={{ height: expandedLocation ? "300px" : "" }}>
                <label htmlFor="location">Location
                &nbsp;&nbsp;
                <a href="/Admin/policieslocations/Location" target="_blank" class="diagonal-arrow-button">
                  &#8599;
                </a>
                </label>
                <div className={`custom-select ${expandedLocation ? "active" : ""}`}>
                  <button type="button" className="select-button" onClick={toggleSelectLocation} aria-expanded={expandedLocation}>
                    <span className="selected-value">{formData.locations ? formData.locations : "Select"}</span>
                    <span className="arrow"></span>
                  </button>
                  <ul className="select-dropdown">
                    {storeInfo.locations.locations.map((location, index) => (
                      <li key={index}>
                        <label htmlFor={`location${index}`}>
                          <input
                            type="radio"
                            id={`location${index}`}
                            name="locations"
                            value={location.name}
                            checked={formData.locations === location.name}
                            onChange={(e) => setFormData({ ...formData,locations_id: location.location.locationId, locations: e.target.value })}
                          />
                          {location.name}
                        </label>
                      </li>
                    ))}
                    <li>
                      <label htmlFor="locationCreateLink">
                        <input
                          type="radio"
                          id="locationCreateLink"
                          name="locations"
                          value="Create Location"
                          checked={formData.locations === "Create Location"}
                          onChange={handleChange}
                        />
                        Create
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
             )} 
  
             
  
              <button className="next-btn" type="submit" style={buttonStyle}>
                {loading ? (
                      <div className="spinner2"></div>
                    ) : (
                      "Save"
                    )}
              </button>
              {error && (
                <p className="error-message" style={errorMessageStyle}>
                  {error}
                </p>
              )}
            </form>
          </div>
           ) : (
            <div>
            <div className="spinner"></div>
            <p className='loading'>LOADING.....</p>
           </div>
          )}
        </div>
        <svg
          width="628"
          height="1356"
          viewBox="0 0 628 1356"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.05">
            <path
              d="M644.788 1349.2C989.849 1349.2 1269.58 1048.74 1269.58 678.099C1269.58 307.461 989.849 7 644.788 7C299.727 7 20 307.461 20 678.099C20 1048.74 299.727 1349.2 644.788 1349.2Z"
              stroke="#0C7489"
              stroke-opacity="0.7"
              stroke-width="40"
              stroke-miterlimit="10"
            />
            <path
              d="M644.789 1258.1C943.01 1258.1 1184.77 998.424 1184.77 678.098C1184.77 357.773 943.01 98.0977 644.789 98.0977C346.568 98.0977 104.812 357.773 104.812 678.098C104.812 998.424 346.568 1258.1 644.789 1258.1Z"
              stroke="#0C7489"
              stroke-opacity="0.7"
              stroke-width="40"
              stroke-miterlimit="10"
            />
            <path
              d="M644.788 1173.94C899.736 1173.94 1106.41 951.943 1106.41 678.099C1106.41 404.254 899.736 182.259 644.788 182.259C389.84 182.259 183.165 404.254 183.165 678.099C183.165 951.943 389.84 1173.94 644.788 1173.94Z"
              stroke="#0C7489"
              stroke-opacity="0.7"
              stroke-width="40"
              stroke-miterlimit="10"
            />
            <path
              d="M644.789 1090.22C856.692 1090.22 1028.47 905.709 1028.47 678.099C1028.47 450.488 856.692 265.974 644.789 265.974C432.885 265.974 261.103 450.488 261.103 678.099C261.103 905.709 432.885 1090.22 644.789 1090.22Z"
              stroke="#0C7489"
              stroke-opacity="0.7"
              stroke-width="40"
              stroke-miterlimit="10"
            />
            <path
              d="M644.789 1015.4C818.22 1015.4 958.813 864.385 958.813 678.099C958.813 491.813 818.22 340.798 644.789 340.798C471.358 340.798 330.765 491.813 330.765 678.099C330.765 864.385 471.358 1015.4 644.789 1015.4Z"
              stroke="#0C7489"
              stroke-opacity="0.7"
              stroke-width="40"
              stroke-miterlimit="10"
            />
            <path
              d="M644.789 935.801C777.292 935.801 884.708 820.424 884.708 678.099C884.708 535.774 777.292 420.396 644.789 420.396C512.285 420.396 404.87 535.774 404.87 678.099C404.87 820.424 512.285 935.801 644.789 935.801Z"
              stroke="#0C7489"
              stroke-opacity="0.7"
              stroke-width="40"
              stroke-miterlimit="10"
            />
          </g>
        </svg>
        
      </div>
      </div>
    );
  };

export default SetDefaultForm;
